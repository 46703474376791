import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SelectItem, ConfirmationService, MessageService } from 'primeng';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { NomrhucabhoraextrService } from '../nomservicios/nomrhucabhoraextr.service';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NomDetHoraExtr } from '../nominterfaces/nomrhucabhoraextr';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Component({
  selector: 'app-nomrhucabhoraextr',
  templateUrl: './nomrhucabhoraextr.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class NomrhucabhoraextrComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  btnNuevo = false;
  btnGuardar = true;
  btnBorrar = true;
  btnRegresar = true;
  btnNuevoGrid = true;
  btnGuardarGrid = true;
  btnCancelarGrid = true;
  btnBorrarGrid = true;
  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;
  btnAnular = true;

  botonnuevo = true;
  botoneliminar = true;
  botoncancelar = true;
  botonbuscar = true;

  defaultColDefHoraExtra;
  public frameworkComponents;

  rowStyle;
  largo = '258';
  rowSelection = 'multiple';

  //detHorasExtra: NomDetHoraExtr [] = [];
  selecteddetHorasExtra: NomDetHoraExtr;


  types: SelectItem[];
  tabla: string;
  consulta: string;
  busquedacampos: any[];
  where: string;
  arregloCons: any[];
  arregloBus: any[];
  displayDialogBusquedaFast = false;
  displayDialogReportes: boolean;

   // reporte
   auxreporte: number = 0;
   strNombreForma: string;
   control=0;


  lblCABHORAEXTR_CODIGO = 0;
  txtPERIODO_FECINI = '';
  txtPERIODO_FECFIN = '';
  txtCABHORAEXTR_ESTADO =  '';
  lblPERIODO_CODIGO = '';
  total25 = 0;
  total50 = 0;
  total100 = 0;

  TXTtotal25 = '';
  TXTtotal50 = '';
  TXTtotal100 = '';


  usuarioPermisos = {
    PERACTUALIZACION: 0,
    PERELIMACION: 0,
    PERINSERCION: 0,
    PERSELECCION: 0,
    PERTODO: 0
  }

  fecha = new Date();
  identiUsuario = '';

  opcion: string;
  condicion: string;
  displayDialogBusquedaTabla = false;
  conteo = 0;
  displayDialogBusquedaNuevo = false;

  spin = false;

  displayDialogDocumentos = false;

  columnDefDetHorasExtra = [
    {
      headerName: 'Código', field: 'EMP_CODIGO', cellEditor: 'cellPrueba', width: 75,  cellStyle: {textAlign: 'right'},
      editable: (params) => {
        return  (this.txtCABHORAEXTR_ESTADO === 'ABIERTO') ? true : false;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Cédula', field: 'EMP_RUCIDE', editable: false, cellEditor: 'cellPrueba', width: 100,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Empleado', field: 'EMP_NOMBRE', editable: false, cellEditor: 'cellPrueba', width: 200,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'RSU', field: 'DETHORAEXTR_RSU', editable: false, cellEditor: 'cellPrueba', width: 75,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: '#25%', field: 'NUM_NUMHORAEXTR25',  cellEditor: 'cellPrueba', width: 90,  cellStyle: {textAlign: 'right'},
      editable: (params) => {
        return  (this.txtCABHORAEXTR_ESTADO === 'ABIERTO') ? true : false;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }, onCellValueChanged: (params) => {
          console.log(params);
          this.obtienevalorhoraextra( Number( params.data.DETHORAEXTR_RSU ), Number( params.data.NUM_NUMHORAEXTR25 ), 'H25')
        }
    },
    {
      headerName: '#50%', field: 'NUM_NUMHORAEXTR50',  cellEditor: 'cellPrueba', width: 90,  cellStyle: {textAlign: 'right'},
      editable: (params) => {
        return  (this.txtCABHORAEXTR_ESTADO === 'ABIERTO') ? true : false;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }, onCellValueChanged: (params) => {
          console.log(params);
          this.obtienevalorhoraextra( Number( params.data.DETHORAEXTR_RSU ), Number( params.data.NUM_NUMHORAEXTR50 ), 'H50')
        }
    },
    {
      headerName: '#100%', field: 'NUM_NUMHORAEXTR100',  cellEditor: 'cellPrueba', width: 90,  cellStyle: {textAlign: 'right'},
      editable: (params) => {
        return  (this.txtCABHORAEXTR_ESTADO === 'ABIERTO') ? true : false;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }, onCellValueChanged: (params) => {
          console.log(params);
          this.obtienevalorhoraextra( Number( params.data.DETHORAEXTR_RSU ), Number( params.data.NUM_NUMHORAEXTR100 ), 'H100')
        }
    },
    {
      headerName: '#Importe 25%', field: 'NUM_VALHORAEXTR25', editable: false, cellEditor: 'cellPrueba', width: 130,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: '#Importe 50%', field: 'NUM_VALHORAEXTR50', editable: false, cellEditor: 'cellPrueba', width: 130,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: '#Importe 100%', field: 'NUM_VALHORAEXTR100', editable: false, cellEditor: 'cellPrueba', width: 130,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Total', field: 'TOTAL', editable: false, cellEditor: 'cellPrueba', width: 75,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
  ]


  constructor(
    private init: NuevoComponentService,
    private datePipe: DatePipe,
    private busquedaSer: InvbusquedaService,
    private permisosService: PermisosService,
    public cabHoraExtrSrv: NomrhucabhoraextrService,
    private confirmationService: ConfirmationService,
    private decimalPipe: DecimalPipe,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.datePipe.transform(this.fecha, 'dd/MM/yy');
    this.identiUsuario = this.usuario.identificacion;
    this.agTable();
  }


  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefHoraExtra = {
      editable: true,
      width: 130,
      objeto: 'detHorasExtra'
    };
  }

  ngOnInit(): void {
    console.log(this.usuario);
    this.getPermisos();
  }



  async getPermisos() {

    await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component).then( res => {
      
      console.log(res);

      this.usuarioPermisos = res[0];
      console.log(this.usuarioPermisos);

    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });

  }

  convertDate(date: string) {
    const fecha = new Date( date );

    let month = '' + (fecha.getMonth() + 1);
    let day = '' + fecha.getDate();
    let year = fecha.getFullYear();

    if( month.length < 2 ) { month = '0' + month }
    if( day.length < 2 ) { day = '0' + day }

    return `${day}/${month}/${year}`;


  }


  busquedaPeriodo(){
    this.types = [
      {label: 'Código', value: 'CABHORAEXTR_CODIGO'},
      {label: 'Periodo', value: 'PERIODO_CODIGO'},
      {label: 'Fec Ini', value: 'PERIODO_FECINI'},
      {label: 'Fen Fin', value: 'PERIODO_FECFIN'}
    ];
    this.busquedacampos = ['', ''];
    this.consulta= 'PERIODO_CODIGO, CABHORAEXTR_CODIGO, PERIODO_FECINI ,PERIODO_FECFIN'
    this.tabla= 'NOM_RHU_cabhoraextr';

    this.where = "";

    this.busquedaSer.busquedaNomCabHoraExtra1().subscribe((res: any[]) => {
      console.log(res);

      res.map( periodo => {
        periodo.PERIODO_FECFIN = this.convertDate(periodo.PERIODO_FECFIN);
        periodo.PERIODO_FECINI = this.convertDate(periodo.PERIODO_FECINI);
      }  )
      
      this.arregloCons = [ ...res ];
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(cabHoraExtra){
    this.getPeriodoHorasExtras( cabHoraExtra.CABHORAEXTR_CODIGO );
    this.displayDialogBusquedaFast = false;
  }

  
  


 async  manejarSenales(valor){
    console.log(valor);
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
      case 'Buscar':
        
        this.busquedaPeriodo();
      break;
      case 'Nuevo':
        this.btnBorrar = true;
        this.control=0;
        this.setTotalCero();
        this.cabHoraExtrSrv.detHorasExtra = [];
        this.getPeriodoEnProceso();
      break;

      case 'Siguiente':
        this.btnGuardar = true;
        this.getSiguiente();
      break;
      case 'Ultimo':
        this.btnGuardar = true;
        this.getUltimo();
      break;
      case 'Anterior':
        this.btnGuardar = true;
        this.getAnterior();
      break;
      case 'Primero':
        this.btnGuardar = true;
        this.getPrimero();
      break;
      case 'Guardar':
        if(this.control === 0){
          this.control=1;
          this.btnGuardar=true;
          this.guardarHorasExtras();
        }
        
      break;
      case 'Borrar':
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar el registro?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmHoraExtra',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {  
            this.eliminarRegistro();  
          },
          reject: () => {
          }
        })        
      break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
        case 'Almacenardoc':
          if (this.lblCABHORAEXTR_CODIGO > 0) {
            const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
            if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
              this.displayDialogDocumentos = true;
            } else {
              this.messageService.add({
                key: 'horaExtra',
                severity: 'info',
                summary: 'Información',
                detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
              });
            }
            
          }
          break;
        
      case 'Cancelar':
        this.reset();
      break;    
    }
  }

  async getSiguiente(){
    await this.cabHoraExtrSrv.getSiguientePeriodoHorasExtras(this.lblCABHORAEXTR_CODIGO).then( cabpagtrab =>{
      console.log(cabpagtrab[0]["MIN(CABHORAEXTR_CODIGO)"]);
      if( cabpagtrab[0]["MIN(CABHORAEXTR_CODIGO)"]  !== null) {

        this.getPeriodoHorasExtras(cabpagtrab[0]["MIN(CABHORAEXTR_CODIGO)"])
      }else {
        this.cabHoraExtrSrv.detHorasExtra = [];
        this.vaciarCampos();
        this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
      }
    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  async getUltimo(){
    await this.cabHoraExtrSrv.getUltimoPeriodoHorasExtras().then( cabpagtrab =>{
      console.log(cabpagtrab[0]["MAX(CABHORAEXTR_CODIGO)"]);
      if( cabpagtrab[0]["MAX(CABHORAEXTR_CODIGO)"]  !== null ){
        this.getPeriodoHorasExtras(cabpagtrab[0]["MAX(CABHORAEXTR_CODIGO)"])
      }else {
        this.cabHoraExtrSrv.detHorasExtra = [];
        this.vaciarCampos();
        this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
      }
      
    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  async getAnterior(){
    await this.cabHoraExtrSrv.getAnteriorPeriodoHorasExtras(this.lblCABHORAEXTR_CODIGO).then( cabpagtrab =>{
      console.log(cabpagtrab[0]["MAX(CABHORAEXTR_CODIGO)"]);

      if( cabpagtrab[0]["MAX(CABHORAEXTR_CODIGO)"] !== null ) {
        this.getPeriodoHorasExtras(cabpagtrab[0]["MAX(CABHORAEXTR_CODIGO)"])
      }else {
        this.cabHoraExtrSrv.detHorasExtra = [];
        this.vaciarCampos();
        this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
      }
      
    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  async getPrimero(){
    await this.cabHoraExtrSrv.getPrimeroPeriodoHorasExtras().then( cabpagtrab =>{
      console.log(cabpagtrab[0]["MIN(CABHORAEXTR_CODIGO)"]);
      if( cabpagtrab[0]["MIN(CABHORAEXTR_CODIGO)"] !== null ){
        this.getPeriodoHorasExtras(cabpagtrab[0]["MIN(CABHORAEXTR_CODIGO)"])
      }else {
        this.cabHoraExtrSrv.detHorasExtra = [];
        this.vaciarCampos();
        this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
      }
      
    } ).catch( e=>  {

      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }

  vaciarCampos(){
      this.lblCABHORAEXTR_CODIGO = 0;
      this.txtPERIODO_FECINI = '';
      this.txtPERIODO_FECFIN = '';
      this.txtCABHORAEXTR_ESTADO =  '';
      this.lblPERIODO_CODIGO = '';
  }

  setTotalCero(){
    this.total25 = 0;
    this.total50 = 0;
    this.total100 = 0;

  }




  async getPeriodoHorasExtras(cabpagtrabCodigo: number){
    this.setTotalCero();
    this.spin = true;
    await this.cabHoraExtrSrv.getPeriodoHorasExtras(cabpagtrabCodigo).then( (cabHExtra: any[]) => {
      console.log(cabHExtra);
      this.lblCABHORAEXTR_CODIGO = cabHExtra[0].CABHORAEXTR_CODIGO;
      this.txtPERIODO_FECINI = this.convertDate(cabHExtra[0].PERIODO_FECINI);
      this.txtPERIODO_FECFIN = this.convertDate(cabHExtra[0].PERIODO_FECFIN);
      this.txtCABHORAEXTR_ESTADO =  cabHExtra[0].CABHORAEXTR_ESTADO;
      this.lblPERIODO_CODIGO = cabHExtra[0].PERIODO_CODIGO;

      if( cabHExtra[0].CABHORAEXTR_ESTADO === 'ABIERTO' ){
        //this.btnGuardar = false;
        this.btnBorrar = false;
        this.botonnuevo = false;
        this.botoneliminar= false;
        this.botoncancelar = false;
        this.botonbuscar = false;
      } else {
        this.btnGuardar = true;
        this.btnBorrar = true;
        this.botonnuevo = true;
        this.botoneliminar= true;
        this.botoncancelar = true;
        this.botonbuscar = true;
      }

      this.getCabHextraEmpleados(cabpagtrabCodigo);
      
    } ).catch( e=>  {

      console.error(e);
      this.spin= false;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
    
  }

  async getCabHextraEmpleados(cabpagtrabCodigo: number){
    
    await this.cabHoraExtrSrv.getCabHextraEmpleados(cabpagtrabCodigo).then( async (empleados: any[]) => {
      
        console.log(empleados.length);
        console.log(empleados[0]);
        console.log(empleados[1]);
        console.log(empleados[2]);
        console.log(empleados[3]);
        console.log(empleados[4]);
        
        this.total25 =0;
        this.total50 = 0;
        this.total100 = 0;

        this.cabHoraExtrSrv.detHorasExtra = [...empleados];
        this.cabHoraExtrSrv.detHorasExtra.map( det => {
          this.total25 = this.total25 + det.NUM_VALHORAEXTR25;
          this.total50 = this.total50 + det.NUM_VALHORAEXTR50;
          this.total100 = this.total100 + det.NUM_VALHORAEXTR100;

          det.TOTAL = (det.NUM_VALHORAEXTR25 +  det.NUM_VALHORAEXTR50 + det.NUM_VALHORAEXTR100).toFixed(2);

          det.NUM_VALHORAEXTR25   = det.NUM_VALHORAEXTR25.toFixed(2);
          det.NUM_VALHORAEXTR50   = det.NUM_VALHORAEXTR50.toFixed(2);
          det.NUM_VALHORAEXTR100  = det.NUM_VALHORAEXTR100.toFixed(2);

          det.DETHORAEXTR_RSU     = det.DETHORAEXTR_RSU.toFixed(2);

          det.NUM_NUMHORAEXTR25 = det.NUM_NUMHORAEXTR25.toFixed(2);
          det.NUM_NUMHORAEXTR50 = det.NUM_NUMHORAEXTR50.toFixed(2);
          det.NUM_NUMHORAEXTR100 = det.NUM_NUMHORAEXTR100.toFixed(2);
        } )

        this.TXTtotal25 = Number(this.total25).toFixed(2);
        this.TXTtotal50 = Number(this.total50).toFixed(2);
        this.TXTtotal100 = Number(this.total100).toFixed(2);
        this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
        //await this.getDatosAdicionales();
      
      
    } ).catch( e =>  {
      console.error(e);
      this.spin= false;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);


    });
  }

  async getDatosAdicionales(){
    //this.setToFixedTotal();
    
      
      

     /*  this.total25 = Number(this.total25);
      this.total50 = Number(this.total50);
      this.total100 = Number(this.total100); */



      await this.cabHoraExtrSrv.encontrarRegistro( 'EMP_CODIGO2', 'NOM_MAEEMPLEADO', `EMP_CODIGO=${this.selecteddetHorasExtra.EMP_CODIGO}`)

        .then( r => { 
          this.conteo++;
          if( r.length > 1 ){
            console.log('aqui hay 2');
          }
          this.selecteddetHorasExtra.EMP_CODIGO2 =  ( r === null || r === undefined ) ? '' : r[0].EMP_CODIGO2;
          this.cabHoraExtrSrv.encontrarRegistro('EMP_RUCIDE, EMP_REMSALUNI', 'NOM_MAEEMPLEADO',`EMP_CODIGO=${this.selecteddetHorasExtra.EMP_CODIGO}` )
            .then( rucide => {
              console.log(rucide);
              //this.conteo++;
              this.selecteddetHorasExtra.EMP_RUCIDE = ( rucide === null || rucide === undefined ) ? '' : rucide[0].EMP_RUCIDE;
              this.selecteddetHorasExtra.DETHORAEXTR_RSU = ( rucide === null || rucide === undefined ) ? '' : rucide[0].EMP_REMSALUNI.toFixed(2);
              
              this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
              let index = this.cabHoraExtrSrv.detHorasExtra.findIndex( x => x === this.selecteddetHorasExtra);
              this.aggrid.gridApi.setFocusedCell(index, 'NUM_NUMHORAEXTR25' );
              // this.cabHoraExtrSrv.encontrarRegistro('EMP_REMSALUNI', 'NOM_MAEEMPLEADO', )
              return;
            }  )
        } ).catch( e=>  {
          this.spin= false;
          console.error(e);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

        });


    
    /* this.spin= false;
    console.log(this.conteo); */
    
  }

  abrirBusquedaCodigo(){
    let emple: NomDetHoraExtr = {
      CABHORAEXTR_CODIGO: null,
      COM_CODIGO: null,
      DETHORAEXTR_NUMSEC: null, // col1
      DETHORAEXTR_ORDEN: null,
      DETHORAEXTR_RSU: null, // col 9
      EMP_CODIGO: null, //col 5
      EMP_NOMBRE: null, // col 8
      NUM_NUMHORAEXTR25: null, // col 10
      NUM_NUMHORAEXTR50: null, // col 11
      NUM_NUMHORAEXTR100: null, // col 12
      NUM_VALHORAEXTR25: null, // 13
      NUM_VALHORAEXTR50: null, // col 14
      NUM_VALHORAEXTR100: null, // col 15
      EMP_CODIGO2: null,
      EMP_RUCIDE: null,
      TOTAL: null,
    }
    let emp_int_empcodigo = this.selecteddetHorasExtra.EMP_CODIGO;
    if( emp_int_empcodigo !== '' ){
      this.cabHoraExtrSrv.getInfoEmpleado(  emp_int_empcodigo, this.txtCABHORAEXTR_ESTADO ).then ( async  res  => {
        console.log(res);
        if(res.length > 0) {

           let apellido =  (res[0].EMP_APELLIDO === null) ? '' : res[0].EMP_APELLIDO;
            let nombre =  (res[0].EMP_NOMBRE === null) ? '' : res[0].EMP_NOMBRE;
            this.selecteddetHorasExtra.EMP_NOMBRE = apellido + ' ' + nombre;
            
            /* return  this.cabHoraExtrSrv.getRSU('EMP_REMSALUNI', 'NOM_MAEEMPLEADO', `EMP_CODIGO= ${emp_int_empcodigo}` ).then( r => {
                console.log(r);
            } )  */

            this.getDatosAdicionales();


        } else { 
          this.abrirDialogBusquedaCodigo();
        }
       

        

        

      }).catch( e=>  {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);

    });
    } else {
      this.abrirDialogBusquedaCodigo();
    }
  }

  abrirDialogBusquedaCodigo(){
    this.types = [
      {label: 'CÓDIGO', value: 'EMP_CODIGO'},
      {label: 'ID', value: 'EMP_RUCIDE'},
      {label: 'APELLIDO', value: 'EMP_APELLIDO'},
      {label: 'NOMBRE', value: 'EMP_NOMBRE'},
      {label: 'fech.Ingreso', value: 'EMP_FECINGRESO'},
      {label: 'ESTADO', value: 'EMP_ACTIVO'},

      
    ];
    this.busquedacampos = ['', '', '', '', '', ''];

    this.consulta= `emp_codigo,emp_rucide,emp_nombre,emp_apellido,EMP_FECINGRESO,EMP_ACTIVO, com_codigo`;

    this.tabla= `NOM_MAEEMPLEADO`;
    this.where = `com_codigo = '01' and rownum < 100` ;
    this.busquedaSer.busquedaNomMaeEmpleado2().subscribe((res: any[]) => {
      

      this.arregloCons = res;// aqui cargo la consulta al array

      console.log(this.arregloCons);
      this.displayDialogBusquedaNuevo = true;
     
    });
  }

  seleccionTablaNuevo(dato) {
    console.log(dato);
    
    this.selecteddetHorasExtra.COM_CODIGO = dato.COM_CODIGO;
    //this.selecteddetHorasExtra.EMP_ACTIVO = dato.EMP_ACTIVO,
    this.selecteddetHorasExtra.EMP_NOMBRE = dato.EMP_APELLIDO + ' ' + dato.EMP_NOMBRE;
    //this.selecteddetHorasExtra.EMP_FECINGRESO = dato.EMP_FECINGRESO,
    this.selecteddetHorasExtra.EMP_CODIGO = dato.EMP_CODIGO;
    this.displayDialogBusquedaNuevo = false;
    this.getDatosAdicionales();
    
  }

  async getPeriodoEnProceso(){
    this.vaciarCampos();
    await this.cabHoraExtrSrv.getPeriodoEnProceso().then( per => {
      console.log(per);
      if( per !==null ){

        this.txtPERIODO_FECINI = this.convertDate(per[0].PERIODO_FECINI);
        this.txtPERIODO_FECFIN = this.convertDate(per[0].PERIODO_FECFIN);
        this.lblPERIODO_CODIGO = per[0].PERIODO_CODIGO;
        this.botonnuevo = false;
        this.botoneliminar = false;
        this.botoncancelar = false;
        this.botonbuscar = false;
        this.txtCABHORAEXTR_ESTADO = 'ABIERTO';

      }

    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  opcionNuevo() {

    let nuevoRegistro: NomDetHoraExtr = {

      CABHORAEXTR_CODIGO: '',
      COM_CODIGO: '01',
      DETHORAEXTR_NUMSEC: 0,
      DETHORAEXTR_ORDEN: this.cabHoraExtrSrv.detHorasExtra.length + 1,
      DETHORAEXTR_RSU: '',
      EMP_CODIGO: '',
      EMP_CODIGO2: '',
      EMP_NOMBRE: '',
      NUM_NUMHORAEXTR25: '',
      NUM_NUMHORAEXTR50: '',
      NUM_NUMHORAEXTR100: '',
      NUM_VALHORAEXTR25: 0,
      NUM_VALHORAEXTR50: 0,
      NUM_VALHORAEXTR100: 0,
      TOTAL: '0.00',
      EMP_RUCIDE: ''
      
    };
    
    this.cabHoraExtrSrv.detHorasExtra.push(nuevoRegistro);
    
    this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
      
    
    
  }

  async guardarHorasExtras(){
    let strAud = ( this.lblCABHORAEXTR_CODIGO === 0 ) ? 'I' : 'A';
    await this.cabHoraExtrSrv.actuCabHoraExtr(this.lblCABHORAEXTR_CODIGO, this.lblPERIODO_CODIGO, this.txtPERIODO_FECINI, this.txtPERIODO_FECFIN   ).then( async res => {
      console.log(res[0][':B1']);
      this.lblCABHORAEXTR_CODIGO = res[0][':B1'];
      return  await this.auditoriagral.registrarAuditoria('NOM_RHU_DETHORAEXTR',
              'NUM:' + this.lblCABHORAEXTR_CODIGO, strAud,
                '', '01', '', '', '', '').toPromise().then(() => {

                  this.guardarDetalles(res[0][':B1']);
                  
              });
      
    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      this.control=0;
      this.btnGuardar=false;
    });
    
  }

  async guardarDetalles(CABHORAEXTR_CODIGO){

    for (let emp of  this.cabHoraExtrSrv.detHorasExtra )  {
      if(  emp.editado  ){

        emp.NUM_NUMHORAEXTR25 = Number(emp.NUM_NUMHORAEXTR25).toFixed(2);
        emp.NUM_NUMHORAEXTR50 = Number(emp.NUM_NUMHORAEXTR50).toFixed(2);
        emp.NUM_NUMHORAEXTR100 = Number(emp.NUM_NUMHORAEXTR100).toFixed(2);
        emp.DETHORAEXTR_RSU = (emp.DETHORAEXTR_RSU==='') ? 0 : parseFloat(emp.DETHORAEXTR_RSU);
        console.log(emp);
       await this.cabHoraExtrSrv.actuDetHoraExtr(CABHORAEXTR_CODIGO, emp.DETHORAEXTR_NUMSEC, Number(emp.EMP_CODIGO), parseFloat(emp.NUM_NUMHORAEXTR25),
       parseFloat(emp.NUM_NUMHORAEXTR50), parseFloat(emp.NUM_NUMHORAEXTR100), parseFloat(emp.NUM_VALHORAEXTR25), parseFloat(emp.NUM_VALHORAEXTR50), parseFloat(emp.NUM_VALHORAEXTR100), emp.DETHORAEXTR_ORDEN, 
            emp.DETHORAEXTR_RSU ).then( r => {
              this.btnBorrar = false;

              console.log('datos guardados');
              this.mensajeAlerta('success', 'Información', 'Datos guardados');
              this.control=0;
              this.btnGuardar=true;
            } ).catch( e=>  {
  
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', mensaje);
              this.control=0;
              this.btnGuardar=false;
            });
      }

    }

  }

  

  cambio(event){
    this.btnGuardar = false;
    this.selecteddetHorasExtra.editado = true;
    console.log(event);
  }

  seleccion(valor){
    if(valor.nameobject !== undefined && valor.object !==undefined ) {
      this.selecteddetHorasExtra = valor.object;
      //console.log(this.selecteddetHorasExtra);
    } else {

      console.log(valor);
      switch (valor) {
        case 'nuevo':
         this.opcionNuevo();
        break;
        case 'btnGridBuscar':
         this.buscarRegistro();
        break;
        case 'cancelar':
          this.btnGuardar = true;
          this.cabHoraExtrSrv.detHorasExtra = [];
          this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
          this.getPeriodoHorasExtras(Number(this.lblCABHORAEXTR_CODIGO));
        break;
        case 'eliminar':
          this.eliminarDetalle();
        break;
      
      }
    }
  }

  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'Cédula', value: 'EMP_RUCIDE'},
      {label: 'Empleado', value: 'emp_nombre'},
    ];
  
    this.busquedacampos = ['', ''];
    this.consulta = "cabhoraextr_codigo, dethoraextr_numsec, emp_codigo,com_codigo, num_numhoraextr25, num_numhoraextr50," +
    "num_numhoraextr100, num_valhoraextr25, num_valhoraextr50," +
    "num_valhoraextr100, dethoraextr_orden, dethoraextr_rsu,NOM_fnc_obtienenmbrempl(emp_codigo) as emp_nombre, NOM_FNC_OBTIENERUCEMPL(emp_codigo) as EMP_RUCIDE";
    this.tabla = 'NOM_RHU_dethoraextr';
    this.condicion = `cabhoraextr_codigo =  '${this.lblCABHORAEXTR_CODIGO}'  order by dethoraextr_orden`;
    this.arregloCons = [];
    this.displayDialogBusquedaTabla = true;
  }

  seleccionTabla(datos){
    console.log(datos);
    this.cabHoraExtrSrv.detHorasExtra = [...datos];
    this.setToFixedTotal();
    this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
    //this.aggrid.refreshaggridindex(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto, 1);
    this.displayDialogBusquedaTabla = false;
  }

  setToFixedTotal(){
    this.cabHoraExtrSrv.detHorasExtra.map( emp => {
      //emp.TOTAL = (emp.NUM_VALHORAEXTR25 + emp.NUM_VALHORAEXTR50 + emp.NUM_VALHORAEXTR100);

      emp.DETHORAEXTR_RSU =  emp.DETHORAEXTR_RSU.toFixed(2) ;
      emp.NUM_NUMHORAEXTR25= emp.NUM_NUMHORAEXTR25.toFixed(2) ;
      emp.NUM_NUMHORAEXTR50 = emp.NUM_NUMHORAEXTR50.toFixed(2);
      emp.NUM_NUMHORAEXTR100 = emp.NUM_NUMHORAEXTR100.toFixed(2);

      emp.TOTAL = (Number(emp.NUM_VALHORAEXTR25) + Number(emp.NUM_VALHORAEXTR50) + Number(emp.NUM_VALHORAEXTR100));
     
    } )
  }

  eliminarRegistro(){
    this.cabHoraExtrSrv.elimCabHoraExtr(this.lblCABHORAEXTR_CODIGO).then( () => {
      console.log('eliminado')
      this.reset();

    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  eliminarDetalle(){
    this.cabHoraExtrSrv.elimDetHoraExtr(this.selecteddetHorasExtra.CABHORAEXTR_CODIGO, this.selecteddetHorasExtra.DETHORAEXTR_NUMSEC).then( () => {
      console.log('eliminado detalle')
      this.btnGuardar = true;
      this.cabHoraExtrSrv.detHorasExtra = [];
      this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
      this.getPeriodoHorasExtras(Number(this.lblCABHORAEXTR_CODIGO));
    } ).catch( e=>  {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }


  async obtienevalorhoraextra( rsu: number, valor: number, codRubr: string){
    await this.cabHoraExtrSrv.obtienevalorhoraextra(rsu, codRubr,
      valor ).then( res => {
        console.log(res)

        switch (codRubr) {
          case 'H25':
            this.selecteddetHorasExtra.NUM_VALHORAEXTR25 = res[0].VALOR_HORAE.toFixed(2);
            break;
          case 'H50':
            this.selecteddetHorasExtra.NUM_VALHORAEXTR50 = res[0].VALOR_HORAE.toFixed(2);
            break;
          case 'H100':
            this.selecteddetHorasExtra.NUM_VALHORAEXTR100 = res[0].VALOR_HORAE.toFixed(2);
            break;
        }

       
        this.selecteddetHorasExtra.TOTAL = (Number(this.selecteddetHorasExtra.NUM_VALHORAEXTR25) + Number(this.selecteddetHorasExtra.NUM_VALHORAEXTR50) + Number(this.selecteddetHorasExtra.NUM_VALHORAEXTR100));
        this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);

        switch (codRubr) {
          case 'H25':
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefHoraExtra.objeto].setFocusedCell(
              this.cabHoraExtrSrv.detHorasExtra.indexOf(this.selecteddetHorasExtra), 'NUM_NUMHORAEXTR50');
            break;
          case 'H50':
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefHoraExtra.objeto].setFocusedCell(
              this.cabHoraExtrSrv.detHorasExtra.indexOf(this.selecteddetHorasExtra), 'NUM_NUMHORAEXTR100');
            break;
          case 'H100':
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefHoraExtra.objeto].setFocusedCell(
              this.cabHoraExtrSrv.detHorasExtra.indexOf(this.selecteddetHorasExtra), 'NUM_NUMHORAEXTR100');
            break;
        }
        
        this.setTxtTotales();
        
    } ).catch( e=>  {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  setTxtTotales(){
    this.total25 = 0;
    this.total50 = 0;
    this.total100 = 0;
    this.cabHoraExtrSrv.detHorasExtra.map( det => {
      this.total25 = this.total25 + Number(det.NUM_VALHORAEXTR25);
      this.total50 = this.total50 + Number(det.NUM_VALHORAEXTR50);
      this.total100 = this.total100 + Number(det.NUM_VALHORAEXTR100);
    } )

    this.TXTtotal25 = this.total25.toFixed(2);
    this.TXTtotal50 = this.total50.toFixed(2);
    this.TXTtotal100 = this.total100.toFixed(2);
  }


  

  reset(){
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.botonnuevo = true;
    this.botoneliminar = true;
    this.botoncancelar = true;
    this.botonbuscar = true;
    this.cabHoraExtrSrv.detHorasExtra = [];
    this.vaciarCampos();
    this.setTotalCero();
    this.aggrid.refreshaggrid(this.cabHoraExtrSrv.detHorasExtra, this.defaultColDefHoraExtra.objeto);
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'horaExtra',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
 


  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }
  

}
