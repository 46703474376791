import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioEsquema } from '../usuarioEsquema';
import { Usuario } from '../usuario';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class ConfInicialesService {

  public url: string;
  public way: string;
  public instanciaBodega = 0;
  public instanciaGrupo = 0;
  public instanciaUnidades = 0;
  public instanciaCalcKardex = 0;
  public instanciaVendedor = 0;
  public instanciaTransportista = 0;
  //CDPJ
  //------seguridades
  public instanciasCorreos =0;
  //------ventas
  public instanciaFacturas = 0;
  public instanciaGuias = 0;
  public instanciaPedidos = 0;
  public instanciasDevFac=0;
  //------compras
  public instanciasFacComp=0;
  public instanciasNotRec=0;
  public instanciasOrdCom=0;
  public instanciasDevFacComp=0;
  public instanciasLiqComp=0;
  //------CXC
  public instanciasPagoRet=0;
  public instanciasCuotasCli=0;
  public instanciasCartActiv=0;
  public instanciasIngreRet=0;
  public instanciasCobranza=0;
  public instanciasTrnabono=0;
  public instanciasTrncancelacion=0;
  public instanciasTrnanticipo=0;
  //------CXP
  public instanciasPagoDoc=0;
  public instanciasCuotas=0;
  public instanciasCartPas=0;
  //-----Bancos
  public instanciasCashManage=0;
  public instanciasCierreCaja=0;
  public instanciasConcilBanca=0;
  public instanciasBandejapago=0;
  public instanciasMoviBancos=0;
  public instanciasMoviCaja=0;
  public instanciasDeposiTarjCredi=0;
  public instanciasTrninversiones=0;
    //----***MAESTROS
    public instanciasClientes=0;
    public instanciasProveedores=0;
    public instanciasArticulos=0;
    public instanciasCntacontable=0;
  //-----*** producción
  public instanciasProdencordprodprocesos=0;
  //----****MATRIZ PORCENTAJE IVA
  public srifetariiva: any[]
  //CDPJ
  public numdecsist = 2;
  public msgclosereload = true;

  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    public back: BackendService
  ) {
    this.back.getUrl(resp => {
      this.url = sessionStorage.getItem('url');
      this.way = sessionStorage.getItem('way');
    });
  }

  getConfiguracion(valor: string): Observable<any> {
    return this.http.post<any>(this.way + '/seg/usuario/83bacc5df287b8000000', {
      elementos: {
        CFG_CODIGO: valor,
      }
    }, this.httpOptions());
  }

  getDecimales(valor: string): Observable<any[]> {
    return this.http.post<any>(this.way + '/seg/usuario/83bad7325655600', {
      elementos: {
        NUMDEC_FORMA: valor,
      }
    }, this.httpOptions());
  }

  getDecimalesP(valor: string) {
    return this.http.post<any>(this.way + '/seg/usuario/83bad7325655600', {
      elementos: {
        NUMDEC_FORMA: valor,
      }
    }, this.httpOptions()).toPromise();
  }

  getobtenerImpuesto(tipo, tarifa) {
    return this.http.post<any>(this.way + '/seg/usuario/617d75ddb95b4000000', {
      elementos: {
        tipo, tarifa
      }
    }, this.httpOptions()).toPromise();
  }

  async obtenerImpuesto(tipo, tarifa) {
    if (tipo == null || tarifa == null) {
      return 0.0;
    }

    let val = 0.0;
    const data = await this.getobtenerImpuesto(tipo, tarifa);
    for (const rs of data) {
      val = rs.IMP_PORCENTAJE;
    }

    return val;
  }

  getNumDecSist() {
    return this.numdecsist;
  }

  setNumDecSist(valor) {
    this.numdecsist = Number(valor);
  }

  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/seg/usuario/617dbbb6caabf0', {
      elementos: {
        AUTORIZACION_CODIGO: clave
      },
    }, this.httpOptions());
  }

  obtenerPermisoUsuario(strIdUsuario) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/c2fb976ed2f7400', {
      elementos: {
        USUARIO: strIdUsuario
      }
    }, this.httpOptions()
    ).toPromise();
  }


  verificarSesion(usuario, esquema, ipFrontend): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/7ddb93e4c56f9000000', {
      USUIDENTIFICACION: usuario,
      ESQUEMA: esquema,
      IPFRONT: ipFrontend
    }, {}).toPromise();
  }

  comprobarSesion(): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/3316cef0b56f8e00000', {},
      this.httpOptions()).toPromise();
  }

  cerrarSesion(): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/63b7b56f8ee4b00', {},
      this.httpOptions()).toPromise();
  }

  insertarEmpresa(NOMBRE, ESQUEMA, USUARIO, PASSWORD): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/4afc76faadbad800000', { NOMBRE, ESQUEMA, USUARIO, PASSWORD },
      this.httpOptions()).toPromise();
  }

  actualizarEmpresa(NOMBRE, ESQUEMA, USUARIO, PASSWORD): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/299df2ab2', { NOMBRE, ESQUEMA, USUARIO, PASSWORD },
      this.httpOptions()).toPromise();
  }

  eliminarEmpresa(NOMBRE): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/3ab2b4aeadbada00000', { NOMBRE },
      this.httpOptions()).toPromise();
  }

  verificarNombreEmpresa(NOMBRE): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/fbb727c98adbb0000000', { NOMBRE },
      this.httpOptions()).toPromise();
  }

  verificarMarca(NOMBRE): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/fbb727c98adbb0000000', { NOMBRE },
      this.httpOptions()).toPromise();
  }

  async conformacion(ruc): Promise<any> {
    return this.http.post<any>(this.way + '/conformacion/677863b98662f00000000000', {
      ruci: ruc
    }, this.httpOptions()).toPromise();
  }

  getRuc(): Promise<any> {
    return this.http.post<any>(this.way + '/conformacion/20eeefcc', {
      elementos: {},
    }, this.httpOptions()).toPromise();
  }


  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Session-Id': sessionStorage.getItem('session'),
        'withCredentials': 'true'
      })
    }
  }

  httpoptionstext() {
    return {
      headers: new HttpHeaders({
        'Session-Id': sessionStorage.getItem('session'),
        'withCredentials': 'true',
        'responseType': 'text'
      })
    }
  }

  httpoptionsblob() {
    return {
      headers: new HttpHeaders({
        'Session-Id': sessionStorage.getItem('session'),
        'withCredentials': 'true',
        'responseType': 'blob'
      })
    }
  }

  async generaactivacionfe(): Promise<any> {
    return this.http.post<any>(this.way + '/conformacion/20ebbb6a533b30000000000', {      
    }, this.httpOptions()).toPromise();
  }

  enviarCorreoActivacionfe(ruc, empresa, cadena) {
    return this.http.post<any[]>(this.way + '/correos/1d7fc95b533b30000000000', {
      elementos: {
        ruc, empresa, cadena
      }
    }).toPromise();
  }

  async actualizardatosactivacion(data): Promise<any> {
    return this.http.post<any>(this.way + '/conformacion/21e56daa533b30000000000', {      
      elementos: {
        data
      }
    }, this.httpOptions()).toPromise();
  }
   //CDPJ
   async obtenerSrifeTariIva() {
    try {
    //this.srifetariiva=[]
    const data = await this.getobtenerSrifeTariIva();
    this.srifetariiva=data
    console.log(this.srifetariiva)
    } catch (error) {
      
    }
    
  }
  getobtenerSrifeTariIva() {
    return this.http.post<any>(this.way + '/seg/configuracion/83bb2b67cee7700000000000000000', {
      elementos: {
      }
    }, this.httpOptions()).toPromise();
  }
  //CDPJ
}
