import { Injectable } from '@angular/core';
import { ProdEncordpro } from '../prodinterfaces/prodencordpro';
import { ProdDetordpro } from '../prodinterfaces/proddetordpro';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { Observable } from 'rxjs';
import { ProdTrncostosind } from '../prodinterfaces/prodtrncostosind';
import { ProdTrntecnicos } from '../prodinterfaces/prodtrntecnicos';
import { ProdTrnmaquinaria } from '../prodinterfaces/prodtrnmaquinaria';
import { ProdTrnliquidacion } from '../prodinterfaces/prodtrnliquidacion';
import { ProdTrnpedidos } from '../prodinterfaces/prodtrnpedidos';
import { ProdTrncontrolcalidad } from '../prodinterfaces/prodtrncontrolcalidad';
import { ProdTrnhorashombre } from '../prodinterfaces/prodtrnhorashombre';

@Injectable({
  providedIn: 'root'
})
export class ProdencordproService {
  public way: string;

  public encordpro: ProdEncordpro[] = [];
  public detordpro: ProdDetordpro[][] = [];
  public trncostosindirectos: ProdTrncostosind[][] = [];
  public trnpedidos: ProdTrnpedidos[][] = [];
  public trnliquidacion: ProdTrnliquidacion[][] = [];
  public trntecnicos: ProdTrntecnicos[][] = [];
  public trnmaquinaria: ProdTrnmaquinaria[][] = [];
  public lotes: any[][] = [];
  public articulo: any[] = [];
  public trncontrolcalidad: ProdTrncontrolcalidad[][] = [];
  public trnhorashombre: ProdTrnhorashombre[][] = [];

  public decimalesCantEnc: number;
  public decimalesCantidad: number;
  public decimalesCosto: number;
  public decimalesTotal: number;
  public decimalesCostoind: number;

  public hmpConfiguracion: any[];
  public selectTipoProd: any[] = [];
  public totalhorashombre: any[] = [];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    private datePipe: DatePipe,
    private init: NuevoComponentService
  ) {
    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  getEncordpro(CodOrdPro): Promise<any[]> {
    return this.http.post<any>(this.way + '/produccion/ordenprod/83baebb31b6e780', {
      elementos: {
        CodOrdPro
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetordpro(FechaInicio, HoraInicia, txtNumero): Promise<any[]> {
    return this.http.post<any>(this.way + '/produccion/ordenprod/83bad7771b6e780', {
      elementos: {
        FechaInicio, HoraInicia, txtNumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getTipoProceso(ArtCodigo): Promise<any[]> {
    return this.http.post<any>(this.way + '/produccion/ordenprod/20eef659c677860000', {
      elementos: {
        ArtCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getNombreEstado(txtEstado): Promise<any[]> {
    return this.http.post<any>(this.way + '/produccion/ordenprod/41ddbe2cbdb9dc00000', {
      elementos: {
        txtEstado
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  extraerCodigoBodega(): Promise<any[]> {
    return this.http.post<any>(this.way + '/produccion/ordenprod/83bacc3650c2f00', {
      elementos: {
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrararticulo(articulo) {
    return this.http.post(this.way + '/produccion/ordenprod/ebb317eed5b5700000000', {
      elementos: {
        articulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarbodega(bodega) {
    return this.http.post(this.way + '/produccion/ordenprod/3aecc5fbb56d7800000', {
      elementos: {
        bodega
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarparteformula(articulo, codigo) {
    return this.http.post(this.way + '/produccion/ordenprod/1f76e4f9315bcb0000000000000', {
      elementos: {
        articulo, codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  completararticulo(articulo) {
    return this.http.post(this.way + '/produccion/ordenprod/cc5b357755b5700000000', {
      elementos: {
        articulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  costopromedio(Codigo, FechaEmision, Hora) {
    return this.http.post(this.way + '/produccion/ordenprod/83bacc73b8cef0000000', {
      elementos: {
        Codigo, FechaEmision, Hora
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  seleccionarregistro(Opcion, txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/71d573192c5d5c0000000000', {
      elementos: {
        Opcion, txtNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  seleccionarregistroref(Opcion, referencia) {
    if (referencia === undefined) {
      referencia = '';
    }
    return this.http.post(this.way + '/produccion/ordenprod/38eab98c962eae00000000000000', {
      elementos: {
        p_Tipo: Opcion,
        p_Cadena: referencia
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  seleccionarregistroproc(Opcion, txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/1c755cc64b17dc0000000000000', {
      elementos: {
        Opcion, txtNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  seleccionarregistrorefproc(Opcion, referencia) {
    if (referencia === undefined) {
      referencia = '';
    }
    return this.http.post(this.way + '/produccion/ordenprod/71d573192c5f7000000000000000000000', {
      elementos: {
        p_Tipo: Opcion,
        p_Cadena: referencia
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarCntaArt(CodArt) {
    return this.http.post(this.way + '/produccion/ordenprod/41dd65faa56fb400000', {
      elementos: {
        ArtCodigo:CodArt
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  actualizarestado(Estado, CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/a677caac9dcea800', {
      elementos: {
        p_numero: CodEncOrdPro,
        p_com_codigo: '01',
        p_estado: Estado
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ecVerificarUsuarioSupervisor(usuarioSup) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/ngngol02dsfkgdr', {
      elementos: {
        UsuarioSup: usuarioSup
      },
    }, this.confIniciales.httpOptions());
  }

  ecVerificarClaveSupervisor(strDialogo, strObjeto, identificacion, usuarioSup, intPestaniaCol) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/t5fb8lfmr1h6cmk', {
      elementos: {
        Dialogo: strDialogo,
        Objeto: strObjeto,
        Identificacion: identificacion,
        UsuarioSup: usuarioSup,
        IntPestaniaCol: intPestaniaCol,
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarestado(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/3aecc5fbb56ddc00000', {
      elementos: {
        txtNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/jwn7qmo1cc3b6ih', {
      elementos: {},
      datos: {
        Clave: clave
      }
    }, this.confIniciales.httpOptions());
  }

  regtranskardexent(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/1b743bb55f9457000', {
      elementos: {
        p_numero: txtNumero,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarFechaIngreso(Fecha, EncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/533be7b99154b0000000', {
      elementos: {
        Fecha, EncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  verificarControlCalidad(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/7ddb93e4c56d98000000000000000', {
      elementos: {
        txtNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarasiprocostos(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/41d776d5b572580000000000', {
      elementos: {
        p_encordpro_numero: txtNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'AMC',
        p_origen: 'PROD',
        p_usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarasiordpro(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/83aeedab6ae4b0000000', {
      elementos: {
        p_encordpro_numero: txtNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'OPP',
        p_origen: 'PROD',
        p_usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarasiordproi(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/1075ddb56d5c9600000000', {
      elementos: {
        p_encordpro_numero: txtNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'OPT',
        p_origen: 'PROD',
        p_usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarasientoaju(txtNumero) {
    return this.http.post(this.way + '/produccion/ordenprod/1075ddb56d5c9400000000', {
      elementos: {
        p_encordpro_numero: txtNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'OPA',
        p_usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getasientomateriaprima(NumOrdPro, Mes) {
    return this.http.post(this.way + '/produccion/ordenprod/20eeab9275fb8c0000000', {
      elementos: {
        NumOrdPro, Mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getasientoproductoenproceso(NumOrdPro, Mes) {
    return this.http.post(this.way + '/produccion/ordenprod/41dd5724ebf71800000000000000000000', {
      elementos: {
        NumOrdPro, Mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getasientoproductoterminado(NumOrdPro, Mes) {
    return this.http.post(this.way + '/produccion/ordenprod/83baae49d7ee3000000000000', {
      elementos: {
        NumOrdPro, Mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getasientoproductoterminadoproceso(NumOrdPro, Mes) {
    return this.http.post(this.way + '/produccion/ordenprod/20eeab920000000000000000000000000', {
      elementos: {
        NumOrdPro, Mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarasientocontable(asiento) {
    return this.http.post(this.way + '/produccion/ordenprod/3ab2b4aeadab9200000', {
      elementos: {
        p_asi_nro: asiento,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarkardex(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/eacad2bab7457000', {
      elementos: {
        CodEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  gettrncostosind(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/41ddeeeecc73b800000', {
      elementos: {
        CodEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  gettrntecnicos(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/20eef777eb997a0000', {
      elementos: {
        CodEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  gettrnmaquinaria(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/83bbdddecad7a8000000', {
      elementos: {
        CodEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  gettrnpedidos(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/10777bbbe5cd93000', {
      elementos: {
        CodEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarcostoindirecto(item) {
    return this.http.post(this.way + '/produccion/ordenprod/10f2b6d56d98e70000000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_trncostosind_fecha: item.TRNCOSTOSIND_FECHA,
        p_trncostosind_hora: item.TRNCOSTOSIND_HORA,
        p_cest_codigo: item.CEST_CODIGO,
        p_cin_codigo: item.CIN_CODIGO,
        p_trncostosind_costo: item.TRNCOSTOSIND_COSTO,
        p_trncostosind_inicial: item.TRNCOSTOSIND_INICIAL
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarcostoindirecto(item) {
    return this.http.post(this.way + '/produccion/ordenprod/14cef998e7712c000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_trncostosind_fecha: item.TRNCOSTOSIND_FECHA,
        p_trncostosind_hora: item.TRNCOSTOSIND_HORA,
        p_cest_codigo: item.CEST_CODIGO,
        p_cin_codigo: item.CIN_CODIGO,
        p_trncostosind_costo: item.TRNCOSTOSIND_COSTO,
        p_trncostosind_inicial: item.TRNCOSTOSIND_INICIAL
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardardatosadicionales(item) {
    return this.http.post(this.way + '/produccion/ordenprod/43cadb55b6abb8000000000000000', {
      elementos: {
        p_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_encordpro_temperatura: item.ENCORDPRO_TEMPERATURA,
        p_encordpro_solvente: item.ENCORDPRO_SOLVENTE,
        p_encordpro_mezcla: item.ENCORDPRO_MEZCLA,
        p_encordpro_tiposolvente: item.ENCORDPRO_TIPOSOLVENTE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardartecnicos(item) {
    return this.http.post(this.way + '/produccion/ordenprod/43cadb55beb99800000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_trntec_codigo: item.TRNTEC_CODIGO,
        p_trntec_nombre: item.TRNTEC_NOMBRE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarmaquinaria(item) {
    return this.http.post(this.way + '/produccion/ordenprod/10f2b6d56ecad800000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_trnmaq_codigo: item.TRNMAQ_CODIGO,
        p_trnmaq_nombre: item.TRNMAQ_NOMBRE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarpedido(item) {
    return this.http.post(this.way + '/produccion/ordenprod/10f2b6d56f2e6d000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_encped_numero: item.ENCPED_NUMERO,
        p_detped_linea: item.DETPED_LINEA,
        p_detped_cantidad: item.DETPED_CANTIDAD,
        p_detped_descripcion: item.DETPED_DESCRIPCION,
        p_detped_codigo: item.DETPED_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarpedido(item) {
    return this.http.post(this.way + '/produccion/ordenprod/533be5572e6c9c0', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_encped_numero: item.ENCPED_NUMERO,
        p_detped_linea: item.DETPED_LINEA,
        p_detped_cantidad: item.DETPED_CANTIDAD
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getunidadcosteo(CodArticulo) {
    return this.http.post(this.way + '/produccion/ordenprod/41ddf5e4d5359800000', {
      elementos: {
        CodArticulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  transformaunidad(Uni_codigo, str_artunidadcosteo, Cantidad) {
    return this.http.post(this.way + '/produccion/ordenprod/eed57e3f1bb2c0000000', {
      elementos: {
        Uni_codigo, str_artunidadcosteo, Cantidad
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  canpartesreservadas(CodArticulo, CodigoBodega, strFechaInicio, strHora) {
    return this.http.post(this.way + '/produccion/ordenprod/62afdcab7d773800000000000', {
      elementos: {
        CodArticulo, CodigoBodega, strFechaInicio, strHora
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  cantidadarticulosguardados(CodArticulo, CodigoBodega, Referencia) {
    return this.http.post(this.way + '/produccion/ordenprod/18abf64d5355bf0000000000000000000', {
      elementos: {
        CodArticulo, CodigoBodega, Referencia
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizartotales(txtTotalCostoMP, txtTotalCostoInd, txtTotalCostoProm, txtTotalProducido, CodOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/299df771d555dc', {
      elementos: {
        txtTotalCostoMP, txtTotalCostoInd, txtTotalCostoProm, txtTotalProducido, CodOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarkardex(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/83aeedab7456d80', {
      elementos: {
        p_numero: CodEncOrdPro,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarArtcaduca(getArtcodigo) {
    return this.http.post(this.way + '/produccion/ordenprod/83baadf58a6f980', {
      elementos: {
        getArtcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  gettrncontrolcalidad(lblEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/1077598bf778ab00000000', {
      elementos: {
        lblEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getanalisisfq(ArtCodigo,EncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/20eef54baa9576000000000', {
      elementos: {
        artCodigo:ArtCodigo,
        encOrdPro:EncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenernumsig(Grupo) {
    return this.http.post(this.way + '/produccion/ordenprod/185f5d776efeb7000', {
      elementos: {
        Grupo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarLotesMP(artcodigo) {
    const id = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
    return this.http.post(this.way + '/produccion/ordenprod/662fcf57aadd70000000', {
      elementos: {
        p_art_codigo: artcodigo,
        p_com_codigo: '01',
        p_id: id
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getProdtrnlote(txtNumero, Detordprolinea) {
    const id = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
    return this.http.post(this.way + '/produccion/ordenprod/20eee7786f777a0000', {
      elementos: {
        txtNumero, Detordprolinea
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetalle(item) {
    return this.http.post(this.way + '/produccion/ordenprod/eacad2bab6d7780000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_detordpro_linea: item.DETORDPRO_LINEA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTodoDetalle(encordpronumero) {
    return this.http.post(this.way + '/produccion/ordenprod/7565695d5b6bbc00000000000', {
      elementos: {
        p_encordpro_numero: encordpronumero,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarContCalidad(item) {
    return this.http.post(this.way + '/produccion/ordenprod/4afc76faadb3180000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_art_codigo: item.ART_CODIGO,
        p_com_codigo: '01',
        p_trncon_estadofisico: item.TRNCON_ESTADOFISICO,
        p_trncon_color: item.TRNCON_COLOR,
        p_trncon_olor: item.TRNCON_OLOR,
        p_trncon_sabor: item.TRNCON_SABOR,
        p_trncon_lugar: item.TRNCON_LUGAR,
        p_trncon_recomendaciones: item.TRNCON_RECOMENDACIONES,
        p_trncon_nota: item.TRNCON_NOTA,
        p_trncon_observaciones: item.TRNCON_OBSERVACIONES,
        p_trncon_fechacaducidad: item.TRNCON_FECHACADUCIDAD,
        p_trncon_fechaultimoana: item.TRNCON_FECHAULTIMOANA,
        p_trncon_codigo: item.TRNCON_CODIGO,
        p_trncon_FechaVigencia: item.TRNCON_FECHAVIGENCIA,
        p_trncon_version: item.TRNCON_VERSION,
        p_trncon_revisado: item.TRNCON_REVISADO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarTrnContCalidad(item) {
    return this.http.post(this.way + '/produccion/ordenprod/257e3b7d56fbbc00000000000000', {
      elementos: {
        p_art_codigo: item.ART_CODIGO,
        p_com_codigo: '01',
        p_trncc_parametro: item.TRNCC_PARAMETRO,
        p_trncc_metodo: item.TRNCC_METODO,
        p_trncc_resultado: item.TRNCC_RESULTADO,
        p_trncc_limite: item.TRNCC_LIMITE,
        p_trncc_orden: item.TRNCC_ORDEN,
        p_encordpro_numero: item.ENCORDPRO_NUMERO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  elimTrnContCalidad(encordpro_numero) {
    return this.http.post(this.way + '/produccion/ordenprod/1d595bbbbb317f000000000', {
      elementos: {
        p_encordpro_numero: encordpro_numero,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  elimTrnContCalidadAll(encordpro_numero) {
    return this.http.post(this.way + '/produccion/ordenprod/eacaddddd98bf8000000000000', {
      elementos: {
        p_ENCORDPRO_NUMERO_1: encordpro_numero,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  okControlCalidad(lblEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/c5198bf778ab18000000', {
      elementos: {
        lblEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarencabezado(item) {
    return this.http.post(this.way + '/produccion/ordenprod/10f2b6d56dd763000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_art_codigo: item.ART_CODIGO,
        p_uni_codigo: item.UNI_CODIGO,
        p_bod_codigo: item.BOD_CODIGO,
        p_encordpro_tipoproc: item.ENCORDPRO_TIPOPROC,
        p_encordpro_estado: item.ENCORDPRO_ESTADO,
        p_encordpro_fechaemision: item.ENCORDPRO_FECHAEMISION,
        p_encordpro_fechainicio: item.ENCORDPRO_FECHAINICIO,
        p_encordpro_cantidad: item.ENCORDPRO_CANTIDAD,
        p_encordpro_horaini: item.ENCORDPRO_HORAINI,
        p_encordpro_horafin: item.ENCORDPRO_HORAFIN,
        p_encordpro_fechafin: item.ENCORDPRO_FECHAFIN,
        p_encordpro_costototal: item.ENCORDPRO_COSTOTOTAL,
        p_encordpro_costounitario: item.ENCORDPRO_COSTOUNITARIO,
        p_encordpro_comentario: item.ENCORDPRO_COMENTARIO,
        p_encordpro_totalcostomp: item.ENCORDPRO_TOTALCOSTOMP,
        p_encordpro_totalcostoind: item.ENCORDPRO_TOTALCOSTOIND,
        p_encordpro_totalcostoprm: item.ENCORDPRO_TOTALCOSTOPRM,
        p_encordpro_totalproducido: item.ENCORDPRO_TOTALPRODUCIDO,
        p_encordpro_referencia: item.ENCORDPRO_REFERENCIA,
        p_bod_codigo_ent: item.BOD_CODIGO_ENT,
        p_encordpro_fechaEntrada: item.ENCORDPRO_FECHAENTRADA,
        p_for_codigo: item.FOR_CODIGO,
        p_encped_numero: item.ENCPED_NUMERO,
        p_encordpro_tipoprod: item.ENCORDPRO_TIPOPROD,
        p_encordpro_lote: item.ENCORDPRO_LOTE,
        p_encordpro_operario: item.ENCORDPRO_OPERARIO,
        p_encordpro_equipo: item.ENCORDPRO_EQUIPO,
        p_encordpro_procedimiento: item.ENCORDPRO_PROCEDIMIENTO,
        p_encordpro_vencimientolote: item.ENCORDPRO_VENCIMIENTOLOTE,
        p_encordpro_horashombre: item.ENCORDPRO_HORASHOMBRE,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardardetalle(item) {
    return this.http.post(this.way + '/produccion/ordenprod/21e56daadb5dd60000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_detordpro_linea: item.DETORDPRO_LINEA,
        p_art_codigo: item.ART_CODIGO,
        p_uni_codigo: item.UNI_CODIGO,
        p_bod_codigo: item.BOD_CODIGO,
        p_detordpro_descripcion: item.DETORDPRO_DESCRIPCION,
        p_detordpro_cantidad: item.DETORDPRO_CANTIDAD,
        p_detordpro_costo: item.DETORDPRO_COSTO,
        p_detordpro_total: item.DETORDPRO_TOTAL,
        p_detordpro_lote: item.DETORDPRO_LOTE,
        p_detordpro_ppa: item.DETORDPRO_PPA,
        p_detordpro_cppa: item.DETORDPRO_CPPA,
        p_detordpro_rendimiento: item.DETORDPRO_RENDIMIENTO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarnumeracion(Clase, DocRef, Nemonico, Serie) {
    return this.http.post(this.way + '/produccion/ordenprod/299df2ab2', {
      elementos: {
        p_NUM_clase: Clase,
        p_COM_codigo: '01',
        p_NUM_docref: DocRef,
        p_NUM_nemonico: Nemonico,
        p_NUM_serie: Serie
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getformulacion(strFechaInicio, strHora, CodigoBodega, CodArticulo, CodigoFormula) {
    return this.http.post(this.way + '/produccion/ordenprod/20eebf1bb7aaa60000', {
      elementos: {
        strFechaInicio, strHora, CodigoBodega, CodArticulo, CodigoFormula
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarexistearticulo(CodArticulo) {
    return this.http.post(this.way + '/produccion/ordenprod/3eedc9f262b6e', {
      elementos: {
        CodArticulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarordenproduccion(clave) {
    return this.http.post(this.way + '/produccion/ordenprod/eacad2bab78db80000000', {
      elementos: {
        p_encordpro_numero: clave,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetallecostoind(item) {
    return this.http.post(this.way + '/produccion/ordenprod/7565695d5b6638000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_cin_codigo: item.CIN_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetallepedido(item) {
    return this.http.post(this.way + '/produccion/ordenprod/1d595a5756f2e70000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_encped_numero: item.ENCPED_NUMERO,
        p_detped_linea: item.DETPED_LINEA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetalletecnico(item) {
    return this.http.post(this.way + '/produccion/ordenprod/3ab2b4aeadf5cc00000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_trntec_codigo: item.TRNTEC_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetallemaquinaria(item) {
    return this.http.post(this.way + '/produccion/ordenprod/1d595a5756ecad000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_COM_codigo: '01',
        p_trnmaq_codigo: item.TRNMAQ_CODIGO,

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetallelote(item) {
    return this.http.post(this.way + '/produccion/ordenprod/7565695d5bae3c0', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_detordpro_linea: item.DETORDPRO_LINEA,
        p_com_codigo: '01',
        p_trnlote_lote: item.TRNLOTE_LOTE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async modeloDetalleVerificaSiEsParteFormula(Codigo, index) {
    let Sw = 0;
    const data: any = await this.verificarparteformula(this.encordpro[this.init.tabs[this.init.tabindex].indice].ART_CODIGO, Codigo);
    for (const rs of data) {
      if (rs.CONTFORMULA !== 0) {
        Sw = 1; // Si el artículo pertenece a la fórmula
      }

      if (Sw == 0) { // Si no pertenece el artículo a la fórmula					
        this.detordpro[this.init.tabs[this.init.tabindex].indice][index].ARTPARFOR = 'X';
        this.detordpro[this.init.tabs[this.init.tabindex].indice][index].COLORFORMULACION = 'Y';
      } else {
        this.detordpro[this.init.tabs[this.init.tabindex].indice][index].ARTPARFOR = '';
        this.detordpro[this.init.tabs[this.init.tabindex].indice][index].COLORFORMULACION = '';
      }
    }
  }

  async SumarTotal() {
    let dbl_costo = 0.0;
    let dbl_cantidad = 0.0;
    let TotalDet = 0.0;
    let TotalCostoMP = 0.0;
    let TotalCostosInd = 0.0;
    let dbl_cantidad_prod = 0.0;
    let CostoPromedio = 0.0;

    dbl_cantidad_prod = Number(this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_CANTIDAD);

    for (const item of this.detordpro[this.init.tabs[this.init.tabindex].indice]) {
      dbl_cantidad = Number(item.DETORDPRO_CANTIDAD);
      dbl_costo = Number(item.DETORDPRO_COSTO);
      TotalDet = dbl_costo * dbl_cantidad;
      item.DETORDPRO_TOTAL = TotalDet.toFixed(this.decimalesTotal);
      item.DETORDPRO_CANTIDAD = dbl_cantidad.toFixed(this.decimalesCantidad);
      item.DETORDPRO_COSTO = dbl_costo.toFixed(this.decimalesCosto);
      TotalCostoMP = TotalCostoMP + TotalDet; // Total Costos Indirectos      
    }

    TotalCostosInd = this.TotalCostosIndirectos();
    await this.calcularhorashombre();
    CostoPromedio = (TotalCostoMP + TotalCostosInd + Number(this.totalhorashombre[this.init.tabs[this.init.tabindex].indice])) / dbl_cantidad_prod;
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALCOSTOMP = TotalCostoMP.toFixed(this.decimalesTotal);
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALCOSTOIND = TotalCostosInd.toFixed(this.decimalesTotal);
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALCOSTOPRM = CostoPromedio.toFixed(this.decimalesTotal);
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALPRODUCIDO = (TotalCostoMP + TotalCostosInd + Number(this.totalhorashombre[this.init.tabs[this.init.tabindex].indice])).toFixed(this.decimalesTotal);

  }

  SumarTotalXProceso() {
    let dbl_costo = 0.0;
    let TotalCostoMP = 0.0;
    let TotalCostosInd = 0.0;
    let dbl_cantidad_prod = 0.0;
    let CostoPromedio = 0.0;

    dbl_cantidad_prod = Number(this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_CANTIDAD);

    for (const item of this.detordpro[this.init.tabs[this.init.tabindex].indice]) {
      dbl_costo = Number(item.DETORDPRO_COSTO);
      TotalCostoMP = TotalCostoMP + dbl_costo; // Total Costos Indirectos
    }

    TotalCostosInd = this.TotalCostosIndirectos();
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALCOSTOMP = TotalCostoMP.toFixed(this.decimalesTotal);
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALCOSTOIND = TotalCostosInd.toFixed(this.decimalesTotal);
    CostoPromedio = (TotalCostoMP + TotalCostosInd) / dbl_cantidad_prod;
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALCOSTOPRM = CostoPromedio.toFixed(this.decimalesTotal);
    this.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_TOTALPRODUCIDO = (TotalCostoMP + TotalCostosInd).toFixed(this.decimalesTotal);

  }

  TotalCostosIndirectos(): number {
    let dbl_costo = 0.0;
    let dbl_CostoTotal = 0.0;

    for (const item of this.trncostosindirectos[this.init.tabs[this.init.tabindex].indice]) {
      dbl_costo = Number(item.TRNCOSTOSIND_COSTO);
      dbl_CostoTotal = dbl_CostoTotal + dbl_costo;
    }

    return dbl_CostoTotal;
  }

  guardarhorashombre(item) {
    return this.http.post(this.way + '/produccion/ordenprod/21e56daadc71b6000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_trnhh_fecha: item.TRNHH_FECHA,
        p_trnhh_horaini: item.TRNHH_HORAINI,
        p_trnhh_horafin: item.TRNHH_HORAFIN,
        p_cin_codigo: item.CIN_CODIGO,
        p_cin_nombre: item.CIN_NOMBRE,
        p_emp_codigo: item.EMP_CODIGO,
        p_trnhh_referencia: item.TRNHH_REFERENCIA,
        p_trnhh_cantidad: item.TRNHH_CANTIDAD,
        p_trnhh_costo: item.TRNHH_COSTO,
        p_trnhh_total: item.TRNHH_TOTAL,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarhorashombre(item) {
    return this.http.post(this.way + '/produccion/ordenprod/533be5564adc7000000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_trnhh_fecha: item.TRNHH_FECHA,
        p_trnhh_horaini: item.TRNHH_HORAINI,
        p_trnhh_horafin: item.TRNHH_HORAFIN,
        p_cin_codigo: item.CIN_CODIGO,
        p_cin_nombre: item.CIN_NOMBRE,
        p_emp_codigo: item.EMP_CODIGO,
        p_trnhh_referencia: item.TRNHH_REFERENCIA,
        p_trnhh_cantidad: item.TRNHH_CANTIDAD,
        p_trnhh_costo: item.TRNHH_COSTO,
        p_trnhh_total: item.TRNHH_TOTAL,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetallehorashombre(item) {
    return this.http.post(this.way + '/produccion/ordenprod/3ab2b4aeadc71c0000000000', {
      elementos: {
        p_encordpro_numero: item.ENCORDPRO_NUMERO,
        p_com_codigo: '01',
        p_trnhh_horaini: item.TRNHH_HORAINI,
        p_trnhh_horafin: item.TRNHH_HORAFIN,
        p_cin_codigo: item.CIN_CODIGO,
        p_emp_codigo: item.EMP_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  gettrnhorashombre(CodEncOrdPro) {
    return this.http.post(this.way + '/produccion/ordenprod/10777bbbc71b5700000000', {
      elementos: {
        CodEncOrdPro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  calcularhorashombre() {
    console.log('calcularhorashombre');
    let total = 0;
    let regex: RegExp = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
    let regexdate: RegExp = new RegExp(/(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/);
    for (const item of this.trnhorashombre[this.init.tabs[this.init.tabindex].indice]) {
      console.log(item.TRNHH_FECHA);
      const fServer = item.TRNHH_FECHA.split('/');
      console.log(fServer);
      let fechaServer;
      if (item.TRNHH_FECHA.length === 10) {
        fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
      } else {
        fechaServer = new Date();
        item.TRNHH_FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      }
      let tmpFechaIni = this.datePipe.transform(fechaServer, 'yyyy-MM-dd');
      let tmpFechaFin = this.datePipe.transform(fechaServer, 'yyyy-MM-dd');
      let tmpHoraIni = item.TRNHH_HORAINI;
      let tmpHoraFin = item.TRNHH_HORAFIN;

      console.log(item.TRNHH_HORAINI.toString().includes('-'));
      if (item.TRNHH_HORAINI.toString().includes('-')) {
        tmpHoraIni = this.datePipe.transform(item.TRNHH_HORAINI, 'HH:mm');
      }
      if (item.TRNHH_HORAFIN.toString().includes('-')) {
        tmpHoraFin = this.datePipe.transform(item.TRNHH_HORAFIN, 'HH:mm');
      }
      /*if (item.TRNHH_FECHA.match(regex) === null) {
        item.TRNHH_FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      }*/
      if (tmpHoraIni.match(regex) === null) {
        item.TRNHH_HORAINI = '00:00';
      }
      if (tmpHoraFin.match(regex) === null) {
        item.TRNHH_HORAFIN = '00:00';
      }

      const FechaIni = new Date(tmpFechaIni + 'T' + tmpHoraIni + ':00');
      const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');

      
      const diffMS = (FechaFin.getTime() - FechaIni.getTime());
      console.log('diffMS', diffMS);
      const diffDays = Math.floor(diffMS / 86400000) * 8; // days
      console.log('diffDays', diffDays);
      const diffHrs = Math.floor((diffMS % 86400000) / 3600000); // hours
      console.log('diffHrs', diffHrs);
      const totalhoras = diffDays + ((diffHrs > 8) ? 8 : diffHrs);
      console.log('totalhoras', totalhoras);

      if (item.TRNHH_COSTO === '' || item.TRNHH_COSTO === null || item.TRNHH_COSTO === undefined) {
        item.TRNHH_COSTO = Number("0").toFixed(this.confIniciales.getNumDecSist());
      }

      const trnhhtotal = totalhoras * Number(item.TRNHH_COSTO);
      total = total + trnhhtotal;
      item.TRNHH_CANTIDAD = totalhoras;
      item.TRNHH_CANTIDAD = Number(item.TRNHH_CANTIDAD).toFixed(this.confIniciales.getNumDecSist());
      item.TRNHH_COSTO = Number(item.TRNHH_COSTO).toFixed(this.confIniciales.getNumDecSist());
      item.TRNHH_TOTAL = trnhhtotal.toFixed(this.confIniciales.getNumDecSist());
    }
    this.totalhorashombre[this.init.tabs[this.init.tabindex].indice] = total.toFixed(this.confIniciales.getNumDecSist());
    
  }
//CDPJ
encontrarArt(CodArt) {
  return this.http.post(this.way + '/produccion/ordenprod/75d98bf76adab8000000', {
    elementos: {
      ArtCodigo:CodArt
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
anularOrdProdu(EncOrdPro,asinro) {
  return this.http.post(this.way + '/produccion/ordenprod/157f555bc6db9e0000', {
    elementos: {
      p_numero:EncOrdPro ,
    p_com_codigo:'01',
    p_encordpro_estado:'A',
    p_asi_nro:asinro
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
anularAsinro(asinro) {
  return this.http.post(this.way + '/produccion/ordenprod/55fd556d5c95f80', {
    elementos: {
    p_asi_nro:asinro,
    p_com_codigo:'01'
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
verificarDetalleOrdProd(EncOrdPro){
  return this.http.post<any[]>(this.way + '/produccion/ordenprod/7ddb93e4c56db0000000000000000', {
    elementos: {
      p_numero:EncOrdPro
    
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
eliminarDetAnFiQu(item): Observable<any> {
  return this.http.post<any>(this.way + '/produccion/ordenprod/3ab2b4aeadb5de0000000000', {
    elementos: {
      p_ENCORDPRO_NUMERO_1: item.ENCORDPRO_NUMERO,
      p_TRNCC_ORDEN_2: item.TRNCC_ORDEN,
      p_COM_codigo_3: '01'
    }
  }, this.confIniciales.httpOptions());
}
consulEyS(EncOrdPro){
  return this.http.post<any[]>(this.way + '/produccion/ordenprod/cc5f9eaf55b76000', {
    elementos: {
      p_numero:EncOrdPro
    
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
eliminarprodliquidacion(EncOrdPro){
  return this.http.post<any[]>(this.way + '/produccion/ordenprod/1d595a575565af00000000000000', {
    elementos: {
      p_encordpro_numero:EncOrdPro,
      p_com_codigo:'01'
      
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
insertarprodliquidacion(item){
  return this.http.post<any[]>(this.way + '/produccion/ordenprod/4afc76faadd65c000000000000000', {
    elementos: {
      p_encordpro_numero: item.ENCORDPRO_NUMERO,
      p_com_codigo:'01',
      p_bod_codigo:item.BOD_CODIGO,
      p_trnliq_codigo:item.TRNLIQ_CODIGO,
      p_trnliq_fechaes:item.TRNLIQ_FECHAES,
      p_trnliq_hora:item.TRNLIQ_HORA,
      p_trnliq_tipo:item.TRNLIQ_TIPO,
      p_trnliq_cantidad:item.TRNLIQ_CANTIDAD,
      p_trnliq_costot:item.TRNLIQ_COSTOT,
      p_trnliq_numero:item.TRNLIQ_NUMERO,
      p_con_codigo:item.CON_CODIGO === null || item.CON_CODIGO === undefined?'':item.CON_CODIGO
      
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
liquidarordprodproceso(item,ajuste){
  return this.http.post<any[]>(this.way + '/produccion/ordenprod/1596bd26ab78db00000000', {
    elementos: {
      p_encordpro_numero:item.ENCORDPRO_NUMERO,
      p_com_codigo:item.COM_CODIGO,
      p_fecha:item.ENCORDPRO_FECHAFIN,
      p_hora:item.ENCORDPRO_HORAFIN,
      p_valor_ajuste:ajuste,
      p_bod_codigo:item.BOD_CODIGO,
      p_art_codigo:item.ART_CODIGO,
      p_encordpro_totalcostomp:item.ENCORDPRO_TOTALCOSTOMP,
      p_encordpro_totalcostoind:item.ENCORDPRO_TOTALCOSTOIND,
      p_encordpro_totalcostoprm:item.ENCORDPRO_TOTALCOSTOPRM,
      p_encordpro_totalproducido:item.ENCORDPRO_TOTALPRODUCIDO
      
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
consultdetliquidacionprod(EncOrdPro){
  return this.http.post<any[]>(this.way + '/produccion/ordenprod/41dd6bbb596bd4000000000000000', {
    elementos: {
      p_numero:EncOrdPro
    
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
ValidaEnSaOrdProd(EncOrdPro) {
   
  return this.http.post<any[]>(this.way +'/produccion/ordenprod/20eebafc5636dc0000', {
    elementos: {
      p_prod_numero: EncOrdPro,
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ

}
