import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvbusquedaService {
  public url: string;
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  ejecutarConsultaPromise(maestro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/662fcf571964b00', {
      elementos: {
        OPCOBJETO: maestro
      }
    }, this.confIniciales.httpOptions()).toPromise();
  } 

  busquedaAnexCodSri(strMes) {
    return this.http.post(this.way + '/inventario/busqueda/5fb8abb9986f380', {
      elementos: {
        CODSRI_CODIGO: strMes
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCon() {
    return this.http.post(this.way + '/inventario/busqueda/bf7198bd94e66000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeBan() {
    return this.http.post(this.way + '/inventario/busqueda/5fb8b55eca72d401', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanEncBandeja() {
    return this.http.post(this.way + '/inventario/busqueda/5f96abbaec5ab00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeExpediente() {
    return this.http.post(this.way + '/inventario/busqueda/bf2d57b29dccb800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanEncCashmng() {
    return this.http.post(this.way + '/inventario/busqueda/5f96abbaec62b80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeBan2(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5f96abd94e5ab00', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeCaj() {
    return this.http.post(this.way + '/inventario/busqueda/5f96abd94e62a80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeAplicacionorg() {
    return this.http.post(this.way + '/inventario/busqueda/2fdc5594e566b200000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeVendedor() {
    return this.http.post(this.way + '/inventario/busqueda/bf7dd7b29df76000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCEncCuadreCaja() {
    return this.http.post(this.way + '/inventario/busqueda/bf318cf29bb73000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanEncconcil() {
    return this.http.post(this.way + '/inventario/busqueda/bf2d5775d8cc6000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeBan3(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5f96abd94e5ab003', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCon2() {
    return this.http.post(this.way + '/inventario/busqueda/5f998bd94e66300', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeBan4() {
    return this.http.post(this.way + '/inventario/busqueda/5f96abd94e5ab004', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeCaj2() {
    return this.http.post(this.way + '/inventario/busqueda/2d6abd94e62a62', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCMaeBanCli() {
    return this.http.post(this.way + '/inventario/busqueda/5b1965396abb2c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCMaeTarjeta() {
    return this.http.post(this.way + '/inventario/busqueda/2d8cb29dd56e6e', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCen() {
    return this.http.post(this.way + '/inventario/busqueda/b662f65398ebb000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeBan5() {
    return this.http.post(this.way + '/inventario/busqueda/2d6abd94e5aae4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComMaeProveedor(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/b662f653b3bc8000', {
      elementos: {
        CONDICION: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaBanTrnTarjeta() {
    return this.http.post(this.way + '/inventario/busqueda/16b55fbbbf55ba0000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaBanTrnBan1() {
    return this.http.post(this.way + '/inventario/busqueda/5ad57eeeea5ab00', {
      elementos: {
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaCxcMaeTarjeta2() {
    return this.http.post(this.way + '/inventario/busqueda/2d8cb29dd56e62', {
      elementos: {
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeBan6() {
    return this.http.post(this.way + '/inventario/busqueda/2d6abd94e5aae6', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncDev() {
    return this.http.post(this.way + '/inventario/busqueda/2d98b3aec6bbe0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComMaeProveedor2(strProCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5b316b29d9de400', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeBodega1(strBodCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/b95ff6539786b800', {
      elementos: {
        BOD_CODIGO: strBodCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeArticulo1() {
    return this.http.post(this.way + '/inventario/busqueda/5caffb29cadf640', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }


  busquedaComMaeProveedor3(strProCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/16cc5aca7677900000', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri2(strSentencia , strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/16abbaca7330de0000', {
      elementos: {
        Sentencia: strSentencia,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri3(strTipoDoc, strSentencia , strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/b55eca7330de7000', {
      elementos: {
        TipoDoc: strTipoDoc,
        Sentencia: strSentencia,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeBodega2(strBodCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5caffb29cbc3600', {
      elementos: {
        BOD_CODIGO: strBodCodigo,
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCen2(strCenCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2d98bd94e63ae2', {
      elementos: {
        CEN_CODIGO: strCenCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncOrdCom(strProCodigo, strFiltro) {
    return this.http.post(this.way + '/inventario/busqueda/b662cebb31b6b000', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        Filtro: strFiltro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncNotRec(strProCodigo, strFiltro) {
    return this.http.post(this.way + '/inventario/busqueda/b662cebb2f8ef000', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        Filtro: strFiltro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncFacPro() {
    return this.http.post(this.way + '/inventario/busqueda/b662cebb1ea66800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncOrdCom2() {
    return this.http.post(this.way + '/inventario/busqueda/16cc59d76636d60000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComMaeProveedor4(strProCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/b662d653b3bc8000', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeBodega3(strBodCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5caffb29cbc35c0', {
      elementos: {
        BOD_CODIGO: strBodCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncNotRec2(strProCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5b31675d97c7780', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  } 

  busquedaComMaeProveedor5(strProCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/b662ca76778fb800', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComMaeProveedor6(strProCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5b31653b3bc7dc0', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri4(strSentencia, strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5aaca7330de6e40', {
      elementos: {
        Sentencia: strSentencia,
        CODSRI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri5(strTipoDoc, strSentencia, strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2eca7330de6e44', {
      elementos: {
        TipoDoc: strTipoDoc,
        Sentencia: strSentencia,
        CODSRI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri6(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2eca7330de6e46', {
      elementos: {
        CODSRI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncOrdCom3(strProCodigo, strFiltro) {
    return this.http.post(this.way + '/inventario/busqueda/5b31675d98db580', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        Filtro: strFiltro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncNotRec3(strProCodigo, strFiltro) {
    return this.http.post(this.way + '/inventario/busqueda/16cc59d765f1de0000', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        Filtro: strFiltro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncFacPro2() {
    return this.http.post(this.way + '/inventario/busqueda/16cc59d763d4cd0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeNumeracion() {
    return this.http.post(this.way + '/inventario/busqueda/2f8a64aca75fd600000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeGrupo1() {
    return this.http.post(this.way + '/inventario/busqueda/5fdd7b29d0dfb40', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia1() {
    return this.http.post(this.way + '/inventario/busqueda/17c5325653b6e80000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri7() {
    return this.http.post(this.way + '/inventario/busqueda/2d577330de6e48', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCon3() {
    return this.http.post(this.way + '/inventario/busqueda/2d98bd94e662c4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCen3() {
    return this.http.post(this.way + '/inventario/busqueda/2d98bd94e63ae4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComMaeProveedor7() {
    return this.http.post(this.way + '/inventario/busqueda/2d98b29d9de3e8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri8() {
    return this.http.post(this.way + '/inventario/busqueda/2eca7330de6e48', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri9() {
    return this.http.post(this.way + '/inventario/busqueda/2eca7330de6e48009', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri10() {
    return this.http.post(this.way + '/inventario/busqueda/5d94e661bcdc800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri11() {
    return this.http.post(this.way + '/inventario/busqueda/5d94e661bcdc840', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCen4() {
    return this.http.post(this.way + '/inventario/busqueda/2d98bd94e63ae440', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia2() {
    return this.http.post(this.way + '/inventario/busqueda/be299653b6e7b800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeBodega4() {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94e5e1a4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncNotRec4() {
    return this.http.post(this.way + '/inventario/busqueda/2d8ebb2f8eedc4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncOrdCom4(strProCodigo, strFiltro) {
    return this.http.post(this.way + '/inventario/busqueda/2dd76636d662c4', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        Filtro: strFiltro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri12(strTipo, strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2d57661bcdc822', {
      elementos: {
        WHERE: strTipo,
        CODSRI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia3(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5f15653b6e7bb80', {
      elementos: {
        REF_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaBanMaeCaj3(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/17c5d94e62a63', {
      elementos: {
        CAJ_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaCxcTrnResumen1() {
    return this.http.post(this.way + '/inventario/busqueda/5f19dddf6ee7ac0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCxcTrnResumen2(strSentencia) {
    return this.http.post(this.way + '/inventario/busqueda/2f8ceeefb773c2', {
      elementos: {
        SENTENCIA: strSentencia
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeTipdia1() {
    return this.http.post(this.way + '/inventario/busqueda/5f198bd95d965c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenTrnProcen() {
    return this.http.post(this.way + '/inventario/busqueda/2f9feeef9de180', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCTrnCobro1() {
    return this.http.post(this.way + '/inventario/busqueda/5f18ceeeecc2f80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  busquedaCXCTrnRet1() {
    return this.http.post(this.way + '/inventario/busqueda/2fdc62b6c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  busquedaCXCTrnAbono1() {
    return this.http.post(this.way + '/inventario/busqueda/17ee6bce6ab8c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  busquedaCXCTrnCancelacion1() {
    return this.http.post(this.way + '/inventario/busqueda/17ee315b', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  busquedaCXCTrnAnticipo1() {
    return this.http.post(this.way + '/inventario/busqueda/17ee315beeeec', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  encontrarRegistro52(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/3aecc5fbb56f6e0000000000', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  busquedaSrifeTariIva(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/17ee315be6e4f7000000000', {
      elementos: {
        parametro:strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  busquedaVenMaeCliente1(strCodCliente) {
    return this.http.post(this.way + '/inventario/busqueda/2eca732b275fa0', {
      elementos: {
        CLI_CODIGO: strCodCliente
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeVendedor2(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/5d94efbaed73700', {
      elementos: {
        WHERE: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri13(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5aaee661bcdc840', {
      elementos: {
        CODSRI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri14() {
    return this.http.post(this.way + '/inventario/busqueda/2d57661bcdc824', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia4(strRefCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2f8a6594edb9e4', {
      elementos: {
        REF_CODIGO: strRefCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCliente2() {
    return this.http.post(this.way + '/inventario/busqueda/1765399593ae2', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCTrnCobro2() {
    return this.http.post(this.way + '/inventario/busqueda/2d8ceeeecc2f62', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComMaeProveedor8() {
    return this.http.post(this.way + '/inventario/busqueda/2eca76778fb9c8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXPEnccuota1() {
    return this.http.post(this.way + '/inventario/busqueda/2d9975d9ec7540', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXPTrnCobro1() {
    return this.http.post(this.way + '/inventario/busqueda/2d99eeeecc2f60', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXPTrnCobro2() {
    return this.http.post(this.way + '/inventario/busqueda/bf735e7354c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXPTrnCobro3(strCodigo, strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2d99eeeecc2f64', {
      elementos: {
        PRO_CODIGO: strCodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri15(strSentencia, strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/16abb30de6c25', {
      elementos: {
        Sentencia: strSentencia,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia5(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/17c5594edb9e5', {
      elementos: {
        REF_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvTrnNumTrasfer1() {
    return this.http.post(this.way + '/inventario/busqueda/5cafdddfdddab80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvEncTransfer1() {
    return this.http.post(this.way + '/inventario/busqueda/5caeebb3bb55f80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeTranport1(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5fecaeed57e6700', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCliente3(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2ff6532b275fa4', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  busquedaMaelotes() {
    return this.http.post(this.way + '/inventario/busqueda/20eecaffb29d5c0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
//CDPJ

busquedaServicios(strCondicion) {
  return this.http.post(this.way + '/inventario/busqueda/2fdc62b7c76ff20000000000', {
    elementos: {
      condicion: strCondicion
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  busquedaInvEncKardex1() {
    return this.http.post(this.way + '/inventario/busqueda/2e5775d8c56da0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeArticulo2(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94e56fa2', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeGrupo1(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/5caffb29d0dfb40', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeImpuesto1() {
    return this.http.post(this.way + '/inventario/busqueda/2feeb29d2b67a0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri16() {
    return this.http.post(this.way + '/inventario/busqueda/b55eca661bcdc800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeUnidad1() {
    return this.http.post(this.way + '/inventario/busqueda/5caffb2bd793540', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia6(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/17c5594edb9e6', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
//CDPJ
busquedaSaciMaeReferencia11(strCondicion) {
  return this.http.post(this.way + '/inventario/busqueda/17ee6bce6ab8a6000000000000000000000000', {
    elementos: {
      parametro: strCondicion
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  busquedaInvMaeGrupo2() {
    return this.http.post(this.way + '/inventario/busqueda/172b29d0dfb22', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeArticulo3() {
    return this.http.post(this.way + '/inventario/busqueda/172b29cadf643', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvEncKardex2() {
    return this.http.post(this.way + '/inventario/busqueda/2e5775d8adb5c2', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvTrnTomaFisica1() {
    return this.http.post(this.way + '/inventario/busqueda/b95fbbbf7167c800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvEncAjuInvNeg1() {
    return this.http.post(this.way + '/inventario/busqueda/172baa7ebba01', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeRl1() {
    return this.http.post(this.way + '/inventario/busqueda/2f8a64aca76ea0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia7(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/2f8ab29db73dc8', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeEmpleado1(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/2ef8b594eb66a0', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCen5(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/16cc594e63ae5', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCon4(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/16cc594e662e4', {
      elementos: {
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeTipoRol1(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5df16b2bb2cef00', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCliente4(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2feeb299593ae4', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCMaeBanCli2(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2d8cb296a65642', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaNomEncRolIndi1(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5ddd7de2b2bb640', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeRubro1(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/2ef8b29dbf2f60', {
      elementos: {
        Where: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  encontrarRegistroProm1Gre(strBodega, strFecha, strHora, strListaprecios, strParametro): Promise<any> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/2fdcd79cd56dd00000000000', {
      elementos: {
        bodega: strBodega,
        fecha: strFecha,
        hora: strHora,
        listaprecios: strListaprecios,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
busquedaNomMaeRubro2(strCondicion) {
  return this.http.post(this.way + '/inventario/busqueda/5fb9af39aabe2c00000000000', {
    elementos: {
      parametro: strCondicion
    },
  }, this.confIniciales.httpOptions());
}
busquedaSaciMaeRlCo(strParametro) {
  return this.http.post(this.way + '/inventario/busqueda/662fcf578a64ac00000000000', {
    elementos: {
      parametro: strParametro
    },
  }, this.confIniciales.httpOptions());
}
busquedaSaciFormulario(strParametro) {
  return this.http.post(this.way + '/inventario/busqueda/662fcf578a64a000000000000', {
    elementos: {
      parametro: strParametro
    },
  }, this.confIniciales.httpOptions());
}
busquedaSaciFormulario1() {
  return this.http.post(this.way + '/inventario/busqueda/662fcf578a64a00000000000000000', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}
busquedaSaciformCorreos(){
  return this.http.post(this.way + '/inventario/busqueda/198bf3d57e376f00000000000000', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}
busquedaSaciMaeCFNGIMPR() {
  return this.http.post(this.way + '/inventario/busqueda/198bf3d5eedd9d000000000', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}
busquedaSaciMaeCFNGIMPRCo(strParametro) {
  return this.http.post(this.way + '/inventario/busqueda/20eeedd9caafd60000', {
    elementos: {
      parametro: strParametro
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  busquedaNomEncRolIndi2() {
    return this.http.post(this.way + '/inventario/busqueda/2eeebef1595da2', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeTipoRol2() {
    return this.http.post(this.way + '/inventario/busqueda/5deca77659de2c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaNomvwmaeEmple1() {
    return this.http.post(this.way + '/inventario/busqueda/2effb29ceb66a0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaNomCabHoraExtra1() {
    return this.http.post(this.way + '/inventario/busqueda/2eec52e38dbba0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeEmpleado2() {
    return this.http.post(this.way + '/inventario/busqueda/5df1653ad9ab940', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeEncUtilidad1() {
    return this.http.post(this.way + '/inventario/busqueda/5df1675d9eecac0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaProEncOrdPro1() {
    return this.http.post(this.way + '/inventario/busqueda/2f3bc3aecc6760', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
//CDPJ
busquedaProEncOrdPro2() {
  return this.http.post(this.way + '/inventario/busqueda/83baebb31b6e7800000000000', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}
//CDPJ
  busquedaInvMaeArticulo4(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94e56fa4', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncped1(strArtCodigo, strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/17f75dd7665c1', {
      elementos: {
        ART_CODIGO: strArtCodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeNumeracion2() {
    return this.http.post(this.way + '/inventario/busqueda/2f8a6594ebfac2', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCliente5(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5feca732b275fc0', {
      elementos: {
        parametro:strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeGrupo2(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/17fb29d0dfb22', {
      elementos: {
        parametro:strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaConMaeCon5(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2d98bd94e662e4', {
      elementos: {
        parametro:strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSegMaeUsuario1() {
    return this.http.post(this.way + '/inventario/busqueda/2f8e8594ef73c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeBodega5(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94e5b604', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeVendedor3(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2feebd94efb9a4', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeArticulo5(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/172bfeca72b65', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaTecMaeEquipo1(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/17d732ca76bc1', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncOrdTra1() {
    return this.http.post(this.way + '/inventario/busqueda/5fdd77636deed40', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaTecEncMant1(strCliCodigo, strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/5f5cc75d9655fc0', {
      elementos: {
        CLI_CODIGO: strCliCodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCEncCuota1() {
    return this.http.post(this.way + '/inventario/busqueda/2d8c75d8cf63a0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCTrnCobro3() {
    return this.http.post(this.way + '/inventario/busqueda/2d8ceeeecc2f64', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeNumeracion3() {
    return this.http.post(this.way + '/inventario/busqueda/2f8ab29d7f59c4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncfac1(strCliCodigo, strReferencia) {
    return this.http.post(this.way + '/inventario/busqueda/2feebbaec7a980', {
      elementos: {
        CLI_CODIGO: strCliCodigo,
        ENCDVF_REFERENCIA: strReferencia
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncped2(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/17f75dd7665c2', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncgre1(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/17f73aec86dc1', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
//CDPJ
busquedaVenEncgre1d(strCondicion) {
  return this.http.post(this.way + '/inventario/busqueda/bf718ade778fb80000000', {
    elementos: {
      condicion: strCondicion
    },
  }, this.confIniciales.httpOptions());
}
obtenerNombreTransport(transportCodigo: string): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/ventas/guiaremision/191j2mkaz9g2eiv', {
    elementos: {
      TRANSPORT_CODIGO: transportCodigo
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  busquedaSaciMaeNumeracion4() {
    return this.http.post(this.way + '/inventario/busqueda/2f8a64acabfac4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncfac2(strNumero) {
    return this.http.post(this.way + '/inventario/busqueda/17f75dd763d42', {
      elementos: {
        ENCFAC_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncgre2() {
    return this.http.post(this.way + '/inventario/busqueda/2feebbaec86dc2', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
//GSRF
busquedaVenEncgrenofirma() {
  return this.http.post(this.way + '/inventario/busqueda/2feebbaec86dc2NF', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}

//GSRF
  busquedaSaciMaeNumeracion5() {
    return this.http.post(this.way + '/inventario/busqueda/5f14c95957f59c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncped3() {
    return this.http.post(this.way + '/inventario/busqueda/17f75dd7665c3', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCliente6(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2feebd94e65646', {
      elementos: {
        CLI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeNumeracion6() {
    return this.http.post(this.way + '/inventario/busqueda/5f14c9594ebfac0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
//CDPJ
busquedaSaciMaeNumeracion6D() {
  return this.http.post(this.way + '/inventario/busqueda/2fdcd79cd5714c00000000000000000000', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}

buscarconsultexistencias(strCampo, strTabla, strCondicion) {
  return this.http.post(this.way + '/inventario/busqueda/17ee315b662fcf0000', {
    elementos: {
      Campo: strCampo,
      Tabla: strTabla,
      Condicion: strCondicion
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  busquedaVenMaeCliente7() {
    return this.http.post(this.way + '/inventario/busqueda/2feebd94e65648', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeGrupo3() {
    return this.http.post(this.way + '/inventario/busqueda/2feebd94e86fc4', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeProvincia1() {
    return this.http.post(this.way + '/inventario/busqueda/5fdd7b29d9de400', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCiudad1() {
    return this.http.post(this.way + '/inventario/busqueda/5fdd7b29cc979c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCanton1(strProvincia) {
    return this.http.post(this.way + '/inventario/busqueda/5fdd7b29cc55fc0', {
      elementos: {
        CLI_PROVINCIA: strProvincia
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeParroquia1(strCanCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/5fdd7b29d956f80', {
      elementos: {
        CAN_CODIGO: strCanCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCMaeBanCli3() {
    return this.http.post(this.way + '/inventario/busqueda/2d8cb296a65644', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri17() {
    return this.http.post(this.way + '/inventario/busqueda/2d57661bcdc828', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeTransport2() {
    return this.http.post(this.way + '/inventario/busqueda/5fdd7b29dddab00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeBodega6() {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94e5e1a6', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncTraPed1() {
    return this.http.post(this.way + '/inventario/busqueda/5fdd775d9ddab40', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeNumeracion7() {
    return this.http.post(this.way + '/inventario/busqueda/2f8a64aca75fc8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCTrnCobro4(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/16c6777766164', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaActMaeActivo1() {
    return this.http.post(this.way + '/inventario/busqueda/2d4ced94e533a0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaActMaeActivo2() {
    return this.http.post(this.way + '/inventario/busqueda/16a676ca72982', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeArticulo6(strBodCodigo, strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94e56fa6', {
      elementos: {
        BOD_CODIGO: strBodCodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia8(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/2f8a6594edb9e8', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia9(strParametro) {
    return this.http.post(this.way + '/inventario/busqueda/17c532ca76dc9', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaCXCMaeBanCli4(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/18cb296abb2a4', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeLote1() {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94eae3a0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaInvMaeLote2(strCodigo) {
    return this.http.post(this.way + '/inventario/busqueda/2e57fd94eae3a2', {
      elementos: {
        ART_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaProdTrnTmpLote1() {
    return this.http.post(this.way + '/inventario/busqueda/bcef0deeefdb5800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }


  busquedaActMaeGrupo() {
    return this.http.post(this.way + '/inventario/busqueda/16a676ca86fd9', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenMaeCiudad2() {
    return this.http.post(this.way + '/inventario/busqueda/17f75eca7325e', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaSaciMaeReferencia10(strTipo) {
    return this.http.post(this.way + '/inventario/busqueda/17c532ca76dcf', {
      elementos: {
        TIPO: strTipo
      },
    }, this.confIniciales.httpOptions());
  }

  busquedaNomMaeEmpleado3() {
    return this.http.post(this.way + '/inventario/busqueda/177c5aca75b23', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaActMaePoliza1() {
    return this.http.post(this.way + '/inventario/busqueda/16a676cace2b2', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaActEncKardex1() {
    return this.http.post(this.way + '/inventario/busqueda/16a675d762b6d', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaActEncSalida1() {
    return this.http.post(this.way + '/inventario/busqueda/2fdc533aebb38a', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaTabla(strCampo, strTabla, strCondicion) {
    console.log(strTabla)
    if (String(strTabla).trim().toUpperCase() === 'SEG_MAEUSUARIO') {
      return this.http.post(this.way + '/inventario/busqueda/2fdcd7b9e56e58', {
        elementos: {
          Campo: strCampo,
          Condicion: strCondicion
        },
      }, this.confIniciales.httpOptions());
    } 
    else if (String(strTabla).trim().toUpperCase() === 'SEG_MAECOMPANIA') {
      return this.http.post(this.way + '/inventario/busqueda/2fdc662d955e4a', {
        elementos: {
          Campo: strCampo,
          Condicion: strCondicion
        },
      }, this.confIniciales.httpOptions());
    } //CDPJ
    else if (String(strTabla).trim().toUpperCase() === 'VEN_ENCGRE') {
      console.log('entro');
      return this.http.post(this.way + '/inventario/busqueda/5fb9af39aaea980000000000000000', {
        elementos: {
          Campo: strCampo,
          Tabla: strTabla,
          Condicion: strCondicion
        },
      }, this.confIniciales.httpOptions());
    }//CDPJ
    //GSRF
    else if (String(strTabla).trim().toUpperCase() === 'CXP_TRNCOBRO') {
      console.log('entro cxp');
      //GSRF
      if(strCondicion !== null && strCondicion !== undefined && strCondicion !== ''){
        console.log('entro cxp 1');
        return this.http.post(this.way + '/inventario/busqueda/2fdcd79cd541d8', {
          elementos: {
            Campo: strCampo,
            Tabla: strTabla,
            Condicion: 'TRNCOBRO_TIPODOC in (\'FC\',\'NC\',\'ND\',\'ANP\',\'DANP\' ) and '+strCondicion+'order by  TRNCOBRO_FECHATRN desc, TRNCOBRO_NRODOC desc  '
          },
        }, this.confIniciales.httpOptions());
      } else{
        console.log('entro cxp 2');
        return this.http.post(this.way + '/inventario/busqueda/2fdcd79cd541d8', {
          elementos: {
            Campo: strCampo,
            Tabla: strTabla,
            Condicion: 'TRNCOBRO_TIPODOC in (\'FC\',\'NC\',\'ND\',\'ANP\',\'DANP\' ) order by  TRNCOBRO_FECHATRN desc '
          },
        }, this.confIniciales.httpOptions());
      }
      //GSRF
    }else {
      return this.http.post(this.way + '/inventario/busqueda/2fdcd79cd541d8', {
        elementos: {
          Campo: strCampo,
          Tabla: strTabla,
          Condicion: strCondicion
        },
      }, this.confIniciales.httpOptions());
    }
    
  }

  busquedaComEncFacPro3() {
    return this.http.post(this.way + '/inventario/busqueda/5b31675d8f53340', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaComEncFacPro4() {
    return this.http.post(this.way + '/inventario/busqueda/2d98b3aec7ab24', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaCXPTrnCobro4() {
    return this.http.post(this.way + '/inventario/busqueda/16ccf77766164', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaVenEncDVF1() {
    return this.http.post(this.way + '/inventario/busqueda/2feebbaec6fde0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
 //GSRF
 busquedaVenEncDVFNOFIRMA() {
  return this.http.post(this.way + '/inventario/busqueda/2feebbaec6fde0NF', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}

//GSRF
  busquedaVenEncFac3() {
    return this.http.post(this.way + '/inventario/busqueda/2feebbaec7a984', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
 //GSRF
 busquedaVennofirma() {
  return this.http.post(this.way + '/inventario/busqueda/2feebbaec7a984NF', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}
//GSRF
  encontrarRegistro1(strCliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b81', {
      elementos: {
        CLI_CODIGO: strCliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro2(strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b82', {
      elementos: {
        codigo: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro3(strCondicion, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b83', {
      elementos: {
        condicion: strCondicion,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro4(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b84', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro5(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b85', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro6(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b86', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro7(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b87', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro8(strBodcodigo,strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b88', {
      elementos: {
        BOD_CODIGO: strBodcodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro9(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84b89', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro10(strBodCodigo, strLista, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84820', {
      elementos: {
        BOD_CODIGO: strBodCodigo,
        DETPED_LISTA: strLista,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro11(strConsulta, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84821', {
      elementos: {
        consulta: strConsulta,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro12(strBodCodigo, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84822', {
      elementos: {
        BOD_CODIGO: strBodCodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro13(strBodega,strFecha,strHora,strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84823', {
      elementos: {
        bodega: strBodega,
        fecha: strFecha,
        hora: strHora,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro14(strBodega,strFecha,strHora,strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84824', {
      elementos: {
        BOD_CODIGO: strBodega,
        Fecha: strFecha,
        Hora: strHora,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro15(strBodega): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84825', {
      elementos: {
        BOD_CODIGO: strBodega
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro16(strWhere, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84826', {
      elementos: {
        where: strWhere,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro17(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84827', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro18(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84828', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro19(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84829', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro20(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84840', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro21(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84841', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro22(strCondicion, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84842', {
      elementos: {
        condicion: strCondicion,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro23(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84843', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro24(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84844', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro25(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84845', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro26(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84846', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro27(strWhere, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84847', {
      elementos: {
        where: strWhere,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro28(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84848', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro29(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84849', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro30(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84860', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarRegistro31(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84861', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro32(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84862', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro33(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84863', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro34(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84864', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro35(strWhere, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84865', {
      elementos: {
        where: strWhere,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro36(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84866', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro37(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84867', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro38(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84868', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro39(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84869', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro40(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84880', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarRegistro41(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84881', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro42(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84882', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro43(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84883', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro44(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84884', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro45(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84885', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro46(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84886', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro47(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84887', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro48(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84888', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro49(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e84889', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro50(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e848a0', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistro51(strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1dbdba12e74a1', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRegistroProm1(strBodega, strFecha, strHora, strListaprecios, strParametro): Promise<any> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/3aecc6dd0cef00', {
      elementos: {
        bodega: strBodega,
        fecha: strFecha,
        hora: strHora,
        listaprecios: strListaprecios,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
encontrarRegistro53(strParametro,artcod): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/inventario/busqueda/3aecc5fbb56f6e000000000a', {
    elementos: {
      ART_CODIGO: artcod,
      strparam:strParametro
    },
  }, this.confIniciales.httpOptions());
}
encontrarRegistroProm1C(strBodega, strFecha, strHora, strListaprecios, strParametro): Promise<any> {
  return this.http.post<any[]>(this.way + '/inventario/busqueda/3aecc5fbb56f6e000000000000000', {
    elementos: {
      bodega: strBodega,
      fecha: strFecha,
      hora: strHora,
      listaprecios: strListaprecios,
      parametro: strParametro
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  encontrarRegistroProm2(strBodega, strParametro): Promise<any> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/1d76636e86762', {
      elementos: {
        BOD_CODIGO: strBodega,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busquedaEncAsi() {
    return this.http.post(this.way + '/inventario/busqueda/16cc5dd762b92', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  buscarFactura(strparametros, strtabla, strcondicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/busqueda/be3d4cefb6a0001', {
      elementos: {
        parametros: strparametros,
        tabla: strtabla,
        condicion: strcondicion
      }
    }, this.confIniciales.httpOptions());
  }
 
  busquedaVenEncordtra(strCondicion) {
    return this.http.post(this.way + '/inventario/busqueda/17ee315bfbaeec', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  //GSRF
  busquedaNEW() {
    return this.http.post(this.way + '/inventario/busqueda/2f3bc3aecc6760NEW', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  busquedaInvEncKardex2NEW(prod) {
    return this.http.post(this.way + '/inventario/busqueda/2e5775d8adb5c2NEW', {
      elementos: {
        p_pro: prod
      },
    }, this.confIniciales.httpOptions());
  }
  busquedaInvEncKardex1NEW(prod) {
    return this.http.post(this.way + '/inventario/busqueda/2e5775d8c56da0NEW', {
      elementos: {
        p_pro: prod
      },
    }, this.confIniciales.httpOptions());
  }
  busquedaComEncFacProimp() {
    return this.http.post(this.way + '/inventario/busqueda/17ee315b75d8f5000000000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  //GSRF
}
